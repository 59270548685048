export const filtrar = (dataMonitor, txt, propsArray) => {
    // Convertir el texto de búsqueda a minúsculas.
    const txtLower = txt.toLowerCase();

    // Filtra la lista usando las propiedades especificadas.
    const listaFiltrada = dataMonitor.filter(el =>
        propsArray.some(prop => 
            el[prop] && el[prop].toLowerCase().includes(txtLower)
        )
    );

    return listaFiltrada;
}

export const acondicionarLista = (lista, excludedKeysArray) => {
    if (lista.length > 0) {
        // Mapea la lista omitiendo las propiedades especificadas en excludedKeysArray.
        const listaAcond = lista.map(item => {
            let obj = {};
            for (let key in item) {
                if (!excludedKeysArray.includes(key)) {
                    obj[key] = item[key];
                }
            }
            return obj;
        });

        const filterArr = Object.keys(listaAcond[0]);

        return { listaAcond, filterArr };
    }

    return { listaAcond: [], filterArr: [] };
}
