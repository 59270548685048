import React, { useEffect, useState } from 'react'
import { obtenerEstadosCivilesAction, obtenerGenerosAction } from '../../redux/datosPersonalesDucks';
import { obtenerPersonaExtranjeraApi } from '../../api/contratacion/datosPersonaExtranjeraApi';
import { obtenerPaisesAction, obtenerRegionesAction } from '../../redux/datosZonasDucks';
import { obtenerNivelesEducacionalesAction } from '../../redux/datosEducacionalesDucks';
import { obtenerDatosPersonalesApi } from '../../api/contratacion/datosPersonalesApi';
import { obtenerDatosBancariosApi } from '../../api/contratacion/datosBancariosApi';
import { obtenerDatosPrevisioApi } from '../../api/contratacion/datosPrevisionApi';
import { obtenerDireccionApi } from '../../api/contratacion/datosDireccionApi';
import { obtenerVacanteApi } from '../../api/contratacion/solicitudVacanteApi';
import { obtenerDatosAfpAction } from '../../redux/datosPrevisionalesDucks';
import { obtenerDocumentoApi } from '../../api/contratacion/documentosApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { obtenerBancosAction } from '../../redux/datosBancariosDucks';
import { obtenerComunasAction } from '../../redux/datosZonasDucks';
import { usePrevision } from '../../hooks/datosPrevisionales';
import { useDispatch, useSelector } from 'react-redux';
import { modalCargando } from '../ui/sweetAlerts';
import { get, getUno } from '../../api/apis';
import {
  CardDatosLaborales, CardDatosPeronales, CardDatosExtranjero
  , CardDatosDireccion, CardDatosPrevisionSalud, CardDatosBancarios, CardDocumentos
} from './CardsDatos';
import Swal from 'sweetalert2';

const VerVacateScreen = () => {
  const { state } = useLocation();
  const { idSolicitud, solicitud } = useParams();
  const { getListaAfp, listadoAfp } = usePrevision();
  const [datosLaborales, setDatosLaborales] = useState([])
  const [datosPersonales, setDatosPersonales] = useState([])
  const [datosExtranjero, setDatosExtranjero] = useState([])
  const [datosDireccion, setDatosDireccion] = useState([])
  const [datosPrevision, setDatosPrevision] = useState([])
  const [datosBancarios, setdatosBancarios] = useState([])
  const [datosDocumento, setdatosDocumento] = useState([])
  const [datoSalud, setDatoSalud] = useState({})

  const dispatch = useDispatch()
  const navigate = useNavigate()

  async function getAFP() {
    modalCargando('Obteniendo datos...');
    await obtenerVacanteApi(state.idPersonaSolicitud, state.idSolicitud)
      .then(res => {
        if (res.status === "success") setDatosLaborales(res.data);
      }).catch(error => console.log("Error :", error))

    await obtenerDatosPersonalesApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosPersonales(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDireccionApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosDireccion(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDatosPrevisioApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") {
          setDatosPrevision(res.data);
          getListaAfp('afp', { jubilado: res.data.jubilado });
        }
      }).catch(error => console.log("Error :", error))

    await obtenerPersonaExtranjeraApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosExtranjero(res.data)
      }).catch(error => console.log("Error: ", error))

    await obtenerDatosBancariosApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setdatosBancarios(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDocumentoApi(state.idPersonaSolicitud).then(res => {
      if (res.status === "success") setdatosDocumento(res.data)
    }).catch(error => console.log("Error :", error))

    Swal.close();
  }

  const getSalud = async () => {
    const resp = await getUno('personasalud', { idPersona: state.idPersonaSolicitud });
    const respPrevision = await get('prevision', { idPersona: state.idPersonaSolicitud });

    const uno = respPrevision.data.data.find((el) => el.id === resp.data.data.id_institucion_salud)
    setDatoSalud({ ...uno, valorPlan: resp.data.data.valor_plan });
  }

  useEffect(() => {
    getAFP();
    getSalud();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(obtenerEstadosCivilesAction());
      await dispatch(obtenerGenerosAction());
      await dispatch(obtenerNivelesEducacionalesAction());
      await dispatch(obtenerPaisesAction());
      await dispatch(obtenerDatosAfpAction());
      await dispatch(obtenerBancosAction());
      await dispatch(obtenerRegionesAction(datosPersonales.id_pais));
    }
    fetchData();
  }, [dispatch])

  useEffect(() => {
    const getComunas = () => { dispatch(obtenerComunasAction(datosDireccion.id_region)); }
    if (datosDireccion.id_region !== '' && datosDireccion.id_region !== undefined) { getComunas(); }
  }, [datosDireccion.id_region]);

  const comunas = useSelector((store) => store.datosZonas.comunas)
    .filter((el) => el.id === datosDireccion.id_comuna)
    .map(item => item.nombre);

  const filtrarSexo = useSelector(store => store.datosPersonales.generos)
    .filter(sexo => sexo.id === datosPersonales.id_persona_genero)
    .map(item => item.nombre)


  const filtrarEstadoCivil = useSelector(store => store.datosPersonales.estadosCiviles)
    .filter(estadiCivil => estadiCivil.id === datosPersonales.id_persona_estado_civil)
    .map(item => item.nombre)

  const filtrarGradoEducacion = useSelector(store => store.datosEducacionales.nivelesEducacionales)
    .filter(grado => grado.id === datosPersonales.id_educacion_nivel)
    .map(item => item.nombre)

  let filtrarPaisPersona = useSelector(store => store.datosZonas.paises)
    .filter(pais => pais.id === datosPersonales.id_pais)
    .map(item => item.nombre)

  if (filtrarPaisPersona.length > 0) {
    const primera = filtrarPaisPersona[0].substring(0, 4)
    if (primera === '0000') { filtrarPaisPersona = filtrarPaisPersona[0].split(' ')[1] }
  }

  let filtrarPaisDireccion = useSelector(store => store.datosZonas.paises)
    .filter(pais => pais.id === datosDireccion.id_pais)
    .map(item => item.nombre)

  if (filtrarPaisDireccion.length > 0) {
    const primera = filtrarPaisDireccion[0].substring(0, 4)
    if (primera === '0000') { filtrarPaisDireccion = filtrarPaisDireccion[0].split(' ')[1] }
  }

  const filtrarRegion = useSelector(store => store.datosZonas.regiones)
    .filter(region => region.id === datosDireccion.id_region)
    .map(item => item.nombre)

  const filtrarAfp = listadoAfp.filter(afp => afp.id === datosPrevision.id_afp).map(item => item.nombre);

  const verHistorial = () => { navigate(`/detalle-vacante/${idSolicitud}`, { state: state }); }
  const editarVacante = () => { navigate(`/editar-vacante/${idSolicitud}`, { state: state }); }
  const continuarProceso = () => { navigate(`/editar-vacante/${idSolicitud}`, { state: state }) }
  const returnBtnEditar = (perfil, estado_solicitud) => (perfil === '4' && [10].includes(estado_solicitud)) || (perfil === '3' && ![15,16].includes(estado_solicitud)) ? true : false;

  return (
    <div className='mx-5'>
      <div className="row">
        <div className="d-grid gap-2 d-md-flex justify-content-md-end my-2 pe-0">
          {(state.solicitud.id_estado_solicitud === 17 && state.id_usuario_creador == localStorage.getItem('user_id')) &&
            <button className="btn btn-success me-md-2" type="button" onClick={continuarProceso}>Continuar Proceso</button>
          }

          <button className="btn btn-arcoprime-st me-md-2" type="button" onClick={() => { navigate('/'); }}>Volver</button>
          <button className="btn btn-arcoprime-st me-md-2" type="button" onClick={verHistorial}>Ver historial</button>
          {(returnBtnEditar(localStorage.getItem('id_perfil'), state.solicitud.id_estado_solicitud) && state.editable) &&
            <button className="btn btn-arcoprime-st" type="button" onClick={() => editarVacante()}>Editar Solicitud</button>
          }
        </div>
        {datosLaborales.length !== 0 && <CardDatosLaborales props={{ datosLaborales }} />}
        {datosPersonales.length !== 0 && <CardDatosPeronales props={{ datosPersonales, filtrarGradoEducacion, filtrarEstadoCivil, filtrarSexo, filtrarPaisPersona }} />}
        {datosExtranjero.length !== 0 && <CardDatosExtranjero props={{ datosExtranjero }} />}
      </div>
      <div className="row">
        {datosDireccion.length !== 0 && <CardDatosDireccion props={{ datosDireccion, filtrarPaisDireccion, filtrarRegion, comunas }} />}
        <div className="col-4 ps-0">
          {datosPrevision.length !== 0 && <CardDatosPrevisionSalud props={{ datosPrevision, datoSalud, filtrarAfp }} />}
        </div>
        <div className="col-4 px-0">
          {datosBancarios.length !== 0 && <CardDatosBancarios props={{ datosBancarios }} />}
        </div>
      </div>
      {datosDocumento.length !== 0 && <CardDocumentos props={{ datosDocumento }} />}
    </div>
  )
}
export default VerVacateScreen