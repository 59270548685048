import React from "react";
import { faMinusCircle, faCheckCircle, faTimesCircle, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

import { TablaSolicitudesReingreso } from './tablaSolicitudes/TablaSolicitudes';
import { TablaHistorialReingreso } from './tablaHistorial/TablaHistorial';
import { modalReingresoAccion, modalEjecutar } from '../ui/sweetAlerts';
import { usePagina } from '../../hooks/hookPagina';
import '../../css/app.css';

export function PestaniasReingreso() {
  return (
    <Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      className="mb-0"
    >
      <Tab eventKey="home" title="Reingresos">
        <TablaSolicitudesReingreso />
      </Tab>
      {['4'].includes(localStorage.getItem('id_perfil')) &&
        <Tab eventKey="profile" title="Historial reingresos">
          <TablaHistorialReingreso />
        </Tab>
      }
    </Tabs>
  );
}

export const ButtonsRRHHyJefeZona = (props) => {
  const { vacante, aprobarReingreso, rechazarReingreso } = props;
  const { setPage } = usePagina(0);
  const idPerfil = localStorage.getItem('id_perfil');
  const txt = "Será redireccionado al formulario de contratación,<br />¿Desea continuar?";
  const navegar = useNavigate();
  const redireccion = (ruta) => {
    setPage(0);
    navegar(ruta);
  }

  return (
    <>
      {(vacante.id_solicitud_estado !== 16) &&
        <button
          className="btn btnAccion btn-danger btn-sm" data-bs-toggle="tooltip" title="Declinar solicitud"
          onClick={() => modalReingresoAccion(vacante, rechazarReingreso, 'Declinar reingreso', 2)}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      }
      {idPerfil !== '4' &&
        <>
          <button
            className="btn btnAccion btn-success btn-sm"
            data-bs-toggle="tooltip" title="Aprobar solicitud"
            onClick={() => modalReingresoAccion(vacante, aprobarReingreso, 'Aceptar reingreso', 1)}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </button>
          {/* {vacante.rechazo_parcial &&
            <button
              className="btn btnAccion btn-warning btn-sm" data-bs-toggle="tooltip" title="Rechazo temporal"
              onClick={() => modalReingresoAccion(vacante, rechazarReingreso, 'Motivo de rechazo')}
            >
              <FontAwesomeIcon icon={faMinusCircle} color='white' />
            </button>
          } */}
        </>
      }
      {(idPerfil === '4' && (vacante.id_solicitud_estado === 5)) &&
        <button
          className="btn btnAccion btn-success btn-sm"
          data-bs-toggle="tooltip" title="Continuar proceso"
          onClick={() => modalEjecutar(redireccion, txt, `/formulario-contratacion/${vacante.id_persona}`)}
        >
          <FontAwesomeIcon icon={faArrowAltCircleRight} />
        </button>
      }
    </>
  )
}
