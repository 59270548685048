// Importando bibliotecas y componentes necesarios
import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';

// Componentes y utils generales
import { mensajeSimple, modalCargando } from '../../../components/ui/sweetAlerts';
import { objStyleCol } from '../../../utils/Constants';
import { sumaStatusContrato } from '../../../utils/funciones';
import { Icono } from '../../../components/ui/Iconos';
import Spinner from '../../../components/ui/Spinner';
import { traerColor, paginationComponentOptions, mostrarPDF, calcularPorcentajeAvance } from './utils';
import { getColumnas } from './tableColumns';
import { customStyles } from './dataTablesStyles';
import { InputBusqueda } from "../../../components/InputBusqueda";
import { filtrar, acondicionarLista } from './filtrar';
import { BtnDescargarExcel } from '../../../components/BtnDescargarExcel';


// Funciones de conexión con APIs
import { get, getUno, post } from '../../../api/apis';

// Componente para validar el contenido HTML
const HtmlValidacion = ({ props }) => {
    return (
        <>
            <span>{props.nombre}</span>
            <div
                className={`bg-${traerColor(props.nombre)} position-absolute`}
                style={{
                    height: '25px',
                    width: '25px',
                    borderRadius: '50%',
                    right: '3px',
                    top: '3px',
                }}
            >
            </div>
        </>
    )
}

const Monitor = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [contratosArray, setContratosArray] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageTable, setPageTable] = useState(1);
    const [dataMonitor, setDataMonitor] = useState([]);
    const [listaBusqueda, setListaBusqueda] = useState([]);
    const [filterArr, setFilterArr] = useState([]);
    const [dataMonitorExcel, setDataMonitorExcel] = useState([]);

    // Función para reenviar contratos
    const reenviarContrato = async (id) => {
        modalCargando('Reenviando');
        const resp = await post('reenvio_contrato', { id_contrato: id })

        if (resp.data.status === 'info') { mensajeSimple('info', resp.data.message, 4500); }
        else if (resp.data.status === 'success') {
            mensajeSimple(resp.data.status, resp.data.message, 4000);
            llamarPagina()
        }
        else { mensajeSimple('error', 'Ha ocurrido un error.'); }
    }

    // Componente botón para reenviar
    const BtnReenvio = ({ dato, id }) => {
        return (
            <>
                {dato === '1'
                    ? <button onClick={() => { reenviarContrato(id) }} className='btn btn-primary btn-sm'>Reenviar</button>
                    : null
                }
            </>
        )
    }

    // Función para seleccionar contratos
    const seleccionarContrato = (obj) => {
        setContratosArray(obj.selectedRows);
    }

    // Función para obtener datos de la página Monitor
    const llamarPagina = async () => {
        const resp = await get('monitor', null, null);
        const resp2 = await getUno('monitorcentral', null, null);
        setDataMonitor(resp2.data.data);
        setLoading(false);

        if (resp === 500) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

        if (resp.data.status === 'info') {
            mensajeSimple(resp.data.status, resp.data.message, 2000);
        }
        else if (resp.data.success) {
            setData(resp.data.data);
        }
    }

    // Funciones para manejar la paginación
    const handlePageChange = page => {
        setPageTable(page);
        llamarPagina(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setPerPage(newPerPage);
        llamarPagina(page);
    };


    useEffect(() => {
        llamarPagina();
    }, []);

    useEffect(() => {
        const obtenerDataExcel = async () => {
            setLoading(true);
            try {
                const resp = await getUno('descarga_excel_monitor', null, null);
                const data = resp.data.data;
                setDataMonitorExcel(data);
                setLoading(false);
            } catch (error) {
                console.error("Error al obtener los datos:", error);
                setLoading(false);
            }
        };

        obtenerDataExcel();
    }, []);

    // Definición de columnas para la tabla
    const columnas = getColumnas(objStyleCol, mostrarPDF, Icono, HtmlValidacion, sumaStatusContrato, BtnReenvio);

    const manejarFiltrar = (txt) => {
        const propsArray = ['Nombre', 'TextoUnidadOrganizativa', 'NombrePlantilla'];
        const excludedKeysArray = ["id", "id_persona", "solicitud_estado", "estado_log", "estado_created_log"];
        const listaFiltrada = filtrar(dataMonitor, txt, propsArray);
        const { listaAcond, filterArr } = acondicionarLista(listaFiltrada, excludedKeysArray);
        setListaBusqueda(listaAcond);
        setFilterArr(filterArr);
    }


    const acondicionarDatos = () => {
        const nombreArchivo = `ReporteDataMonitor_${new Date().toISOString().slice(0, 10)}`;

        const mapeoColumnas = {
            'IdLote': 'ID LOTE',
            'NPersonal': 'N° PERSONAL',
            'Nombre': 'NOMBRE COLABORADOR',
            'Rut': 'RUT',
            'Division': 'DIVISION',
            'TextoUnidadOrganizativa': 'UNIDAD ORGANIZATIVA',
            'Subdivision': 'SUBDIVISION',
            'NombrePlantilla': 'PLANTILLA',
            'Cargo': 'CARGO',
            'FechaIngreso': 'FECHA DE INGRESO',
            'FechaEmisionContrato': 'EMISION CONTRATO',
            // Aqui se pueden añadir mas columnas de ser necesario
        };

        const nombresEstado = ['VALIDACION JEFE REMUNERACIONES', 'VALIDACION ADMINISTRADOR', 'COLABORADOR'];
        const columNombresTabla = [...Object.values(mapeoColumnas), ...nombresEstado, 'PORCENTAJE AVANCE'];

        // Asumir un ancho de columna basado en el nombre más un espacio adicional
        const anchoColumnas = columNombresTabla.map(nombre => nombre.length + 5);

        // Filtramos previamente los datos que tienen un porcentaje de avance del 100%
        const datosFiltrados = dataMonitorExcel.filter(row => {
            const porcentajeAvance = calcularPorcentajeAvance(row.status_contrato);
            return porcentajeAvance !== '100.00';
        });

        const datosTabla = datosFiltrados.map(row => {
            let nuevoObj = {};
            Object.entries(mapeoColumnas).forEach(([key, value]) => {
                nuevoObj[value] = row[key] || '';
            });
        
            let porcentajeAvance = calcularPorcentajeAvance(row.status_contrato);
            nuevoObj['PORCENTAJE AVANCE'] = `${porcentajeAvance}%`;
        
            nombresEstado.forEach((estado, i) => {
                nuevoObj[estado] = row.status_contrato && row.status_contrato[i]
                    ? row.status_contrato[i].estado_validador.nombre
                    : 'N/A';
            });
        
            if (row.status_contrato && row.status_contrato.length) {
                // Se obtiene la ultima observacion de status_contrato
                let ultimoStatus = row.status_contrato[row.status_contrato.length - 1];
                nuevoObj['OBSERVACIONES'] = ultimoStatus.observaciones || 'Sin Observaciones';
            } else {
                nuevoObj['OBSERVACIONES'] = 'Sin Observaciones';
            }
        
            return nuevoObj;
        });
        columNombresTabla.push('OBSERVACIONES');

        const columMuestreo = columNombresTabla;

        return {
            nombreArchivo,
            columNombresTabla,
            datosTabla,
            columMuestreo,
            anchoColumnas
        };
    };

    // Renderización del componente
    return (
        <>
            <div className="row">
                <div className="col-sm-12 ">
                    <div className="card cardPestania">
                        <div className="card-header bgArcopime h5 d-flex justify-content-between" style={{ padding: '6px 8px 10px 8px' }}>
                            Monitor
                            <InputBusqueda ejecutar={manejarFiltrar} />
                        </div>
                        {
                            loading
                                ? (<Spinner />)
                                : (
                                    <div className="card-body contenedorTabla">
                                        {dataMonitor.length > 0
                                            ?
                                            (
                                                <DataTable
                                                    title={false}
                                                    responsive={true}
                                                    columns={columnas.admin}
                                                    data={listaBusqueda.length > 0 ? listaBusqueda : dataMonitor}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationDefaultPage={pageTable}
                                                    paginationPerPage={perPage}
                                                    paginationTotalRows={totalRows}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    customStyles={customStyles}
                                                    onSelectedRowsChange={seleccionarContrato}
                                                    dense
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="450px"
                                                // sortable={true}
                                                />
                                            )
                                            :
                                            <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                                        }
                                    </div>
                                )
                        }
                    </div>

                </div>
            </div>
            {!loading && dataMonitorExcel.length > 0 && (
            <div className="row mt-3">
                <div className="col-sm-12">
                    <BtnDescargarExcel ejecutarFn={acondicionarDatos} habilitado={dataMonitorExcel.length > 0} />
                </div>
            </div>
        )}


        </>
    )
}

export default Monitor;