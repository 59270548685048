import { BASE_URL_PRODUCCION } from "../../utils/Constants";

export const guardarDatosBancariosApi = async (datos, idPersona, metodo = "POST") => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personadatosbancarios`
        const { formaDePago, banco, tipoCuenta, numeroCuenta } = datos;
        const params = {
            method: metodo,
            headers: {
                'Content-Type': 'application/json',
                'idUsuario': `${1}`,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                numero_cuenta: numeroCuenta,
                id_banco: banco,
                id_tipo_cuenta: tipoCuenta,
                tipo_pago: parseInt(formaDePago),
                id_persona: idPersona
            })
        }
        const res = await fetch(url, params)
        let resJSON = await res.json(datos, idPersona)

        if(resJSON.hasOwnProperty('existe')){
            resJSON = await actualizarDatosBancariosApi(datos, idPersona)
        }

        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }
}

export const obtenerDatosBancariosApi = async (idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personadatosbancarios?idPersona=${idPersona}`
        const params = {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }      
        const res = await fetch(url,params)
        const resJSON = await res.json()

        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }
}

export const actualizarDatosBancariosApi = async (datos, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personadatosbancarios`
        const { formaDePago, banco, tipoCuenta, numeroCuenta } = datos;
        const params = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'idUsuario': `${1}`,
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                numero_cuenta: numeroCuenta,
                id_banco: banco,
                id_tipo_cuenta: tipoCuenta,
                tipo_pago: parseInt(formaDePago),
                id_persona: idPersona
            })
        }
        const res = await fetch(url, params)
        const resJSON = await res.json()

        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }
}