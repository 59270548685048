import React, { useState, useContext } from 'react'
import { obtenerDatosPersonaSolicitanteAction } from '../redux/datosPersonaSolicitanteDucks';
import Accordion from 'react-bootstrap/Accordion';
import { guardarSolicitudReingreso } from '../api/contratacion/solicitudVacanteApi';
import { mensajeSimple, modalCargando } from './ui/sweetAlerts';
import { DataContext } from '../contextData/contextData';
import { useReingreso } from '../hooks/datosLaborales';
import { useNavigate } from 'react-router-dom';
import rut from '@mlezcano1985/rut-formatter';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { validateRut } from 'rutlib/lib';
import { getUno } from '../api/apis';
import { useFormik } from 'formik';
import Spinner from './ui/Spinner';
import Swal from 'sweetalert2';
import { objNoSuccess } from '../utils/Constants';
import { formatRUT } from '../utils/funciones';

const ConsultarRutScreen = () => {
  const dispatch = useDispatch();
  const { setReingreso } = useReingreso();
  const { setearPagina } = useContext(DataContext);
  const personSolicitante = useSelector(store => store.datosPersonaSolicitante);
  const loading = useSelector(store => store.datosPersonaSolicitante.loading);
  const [viewMsj, setViewMsj] = useState(false);
  const navigate = useNavigate();

  const validate = values => {
    const errors = {};
    if (!values.rut) { errors.rut = 'Debe ingresar un rut.'; }
    else if (values.rut.length < 6 || !validateRut(values.rut)) { errors.rut = 'El rut es invalido.'; }
    return errors;
  }

  const formik = useFormik({
    initialValues: { rut: '' },
    validate,
    onSubmit: values => {
      setReingreso(false);
      verificarRut(values)
    }
  });

  const verificarRut = async (values) => {
    modalCargando('Consultando...');

    const resp = await dispatch(obtenerDatosPersonaSolicitanteAction(values.rut));

    !resp ? setTimeout(() => Swal.close(), 2000) : Swal.close()
    setViewMsj(resp)
  }

  const iniciarSolicitudTrabajadorAntiguio = async (idPersona) => {
    const txt = 'Se procederá a enviar a validación para posteriormente continuar con el proceso.';
    Swal.fire({
      icon: 'info',
      title: txt,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: 'red',
      showLoaderOnConfirm: true,
      reverseButtons: false, // orden botones al reves
      customClass: {
        container: 'containerMensajeSimple',
        title: 'titleMensajeSimple',
        text: 'txtLabellInput',
        popup: `popUpSweetCancelarEnviar`,
        confirmButton: 'btnCopec me-4',
        cancelButton: 'btnAtras',
      },
      buttonsStyling: false,
      preConfirm: () => {
        return guardarSolicitudReingreso(idPersona)
          .then(response => {
            if (response.status !== 'success') {
              throw new Error(response.message)
            }
            return response
          })
          .catch(error => { Swal.showValidationMessage(error) })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        mensajeSimple('success', result.value.message, 5000);
        setViewMsj(false)
      }
    });
  }


  return (
    <>
      <div className="row">
        <div className="col-12 col-md-3 my-2"></div>
        <div className="col-12 col-md-6 my-2 justify-content-center d-flex">
          <div className="card" style={{ width: '500px' }}>
            <div className="card-header bgArcopime">Verificación de RUT a contratar</div>
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="rut" className="form-label">Rut solicitante</label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="rut"
                    maxLength={10}
                    aria-describedby="rutHelper"
                    onChange={
                      (e) => {
                        const txt = e.target.value.replace(/([A-JL-Za-jl-zÑñ.,\-:;´¿¡+=_!@#$%~^&*('"{><\`/?}|)\s[\]])/g, '');
                        formik.setValues({
                          ...formik.values,
                          rut: rut.format(txt) !== null ? rut.format(txt).replace(/\./g, '') : txt.replace(/\./g, '')
                        })
                      }
                    }
                    value={formik.values.rut.replace(/\./g, '')}
                  />
                  {formik.errors.rut ? <span className='badge rounded-pill bg-danger my-2'>{formik.errors.rut}</span> : null}
                </div>
                <div className="d-grid gap-2">
                  <button type="submit" className="btn btn-primary">Consultar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3 my-2"></div>
      </div>
      {
        personSolicitante.dataPersona.rut && viewMsj && personSolicitante.dataPersona.status !== 'info' && (
          <div className="row mt-3">
            <div className="col-12 col-md-3"></div>
            <div className="col-12 col-md-6 justify-content-center d-flex">
              <div className="card" style={{ width: '500px' }}>
                <div className="card-header bgArcopime">Observaciones</div>
                {
                  loading && !personSolicitante.dataPersona.rut ? (<Spinner />) : (
                    <div className="card-body">
                      <h6>{personSolicitante.dataPersona.rut ? `Rut consultado: ${formatRUT(personSolicitante.dataPersona.rut)}` : null}</h6>
                      <div className="d-grid gap-2">
                        {!personSolicitante.dataPersona.data.bloqueado && !personSolicitante.dataPersona.data.registro_sap && personSolicitante.dataPersona.data.id_persona == null && (
                          console.log(personSolicitante.dataPersona.data),
                          console.log(personSolicitante.dataPersona.data.registro_sap),

                          <button
                            className="btn btn-primary"
                            onClick={() => { setearPagina(0); navigate("/formulario-contratacion"); }}
                          >
                            Ingresar contratación
                          </button>
                        )
                        }
                      </div>
                      {
                        (!personSolicitante.dataPersona.data.bloqueado &&
                          personSolicitante.dataPersona.data.registro_sap) && (
                          <div className="mb-3 mt-2">
                            <div className="alert alert-warning" role="alert">
                              La Gerencia de Personas determinó que <strong>{personSolicitante.dataPersona.data.nombre}</strong>, fue desvinculado(a) con  una causal que REQUIERE VALIDACIÓN por parte de Procesos & Remuneraciones para continuar con su proceso.
                            </div>
                            <div className="d-grid gap-2">


                              <button
                                className="btn btn-success" data-bs-toggle="tooltip"
                                onClick={() => iniciarSolicitudTrabajadorAntiguio(personSolicitante.dataPersona.data.id_persona)}
                              >
                                Iniciar solicitud
                              </button>
                            </div>
                          </div>
                        )
                      }

                      {
                        personSolicitante.dataPersona.data.bloqueado && (
                          <div className="mb-3 mt-2">
                            <div className="alert alert-danger" role="alert">
                              <p>La Gerencia de Personas determinó que <strong>{personSolicitante.dataPersona.data.nombre}</strong>, fue desvinculado(a) con  una Causal que <strong>NO PERMITE</strong> continuar con su proceso.</p>
                            </div>
                          </div>
                        )
                      }

                      {
                        (!personSolicitante.dataPersona.data.bloqueado &&
                          !personSolicitante.dataPersona.data.registro_sap &&
                          personSolicitante.dataPersona.data.id_persona !== null) && (
                          <Accordion className='mt-2'>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>{personSolicitante.dataPersona.message}</Accordion.Header>
                              <Accordion.Body>
                                <div className="mb-3">
                                  <div className="alert alert-warning" role="alert">
                                    <p>Esto puede deberse a que la solicitud ya culminó o hubo un error al procesar la solicitud inicial de este colaborador.</p>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-12 col-md-3"></div>
          </div>
        )
      }
      {personSolicitante.dataPersona.status === 'info' &&
        <div className="row my-5">
          <div className="col-12 col-md-3"></div>
          <div className="col-12 col-md-6 justify-content-center d-flex">
            <div className="card" style={{ width: '500px' }}>
              <div className="alert alert-info mb-0 text-center" role="alert">
                <b>{personSolicitante.dataPersona.message}.</b>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3"></div>
        </div>
      }
    </>
  )
}

export default ConsultarRutScreen