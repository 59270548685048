import moment from "moment";
import Badge from 'react-bootstrap/Badge';
import { BASE_URL_INTRANET } from "../utils/Constants";


export const returNombreRuta = (path) => {
  let txt = '';
  switch (path) {
    case '/':
      txt = 'Solicitudes en progreso'
      break;
    case '/listSolicitudAprobadas':
      txt = 'Solicitudes aprobadas'
      break;
    case '/listSolicitudReingreso':
      txt = 'Solicitudes de reingreso'
      break;
    case '/consulta-rut':
      txt = 'Consulta RUT'
      break;
    case '/envio-sap':
      txt = 'Envío a SAP'
      break;
    case '/resumen-solicitudes':
      txt = 'Resumen de solicitudes'
      break;
    case '/monitor':
      txt = 'Monitor'
      break;
    default:
      txt = '';
      break;
  }
  return txt;
}

export const devolverPlural = obj => obj.idContrato.length > 1 ? 's' : '';

export const cerrarSession = () => {
  localStorage.clear();
  window.location = `${BASE_URL_INTRANET}/home`;
}

export const sumaStatusContrato = (arrStatus) => {
  const indx = arrStatus.findIndex((el) => el.estado_validador.nombre === "Rechazado");
  return indx !== -1
    ? "Anulado"
    : `${arrStatus.reduce((acc, val) => {
      return val.estado_validador.nombre === "Aprobado" ? acc += parseFloat(val.validador.porcentaje) : acc += 0
    }, 0)}%`
}

export const momentFecha = (txt, hm = false) => hm == true ? moment(txt).format('DD-MM-yyyy HH:mm') : moment(txt).format('DD-MM-yyyy');

export const fechaMinimaHasta = (fecha) => (moment(fecha).add(1, 'y').format('yyyy-MM-DD')).toString()

export const evaluarEstado = (estado, perfil, idBotton) => {
  let visible = false;
  switch (idBotton) {
    case '1':
      visible = true;
      break;
    case '3':
      if ((estado === 5) && (['4'].includes(perfil))) { visible = true; }
      break;
    case '2':
      if (['3'].includes(perfil) && [4,6].includes(estado)) {
        return true;
      }

      if (([9, 3, 8, 12].includes(estado)) && (['3'].includes(perfil))) {
        visible = true;
      }
      break;
    case '4':
      if (estado <= 12 || estado === 17) {
        visible = true;
      }
      break;
    case '5':
      if ([9, 3, 8, 6, 4, 12].includes(estado) && (['3'].includes(perfil))) {
        visible = true;
      }
      break;
    case '6':
      if (([7, 10].includes(estado)) && (['4'].includes(perfil))) { visible = true; }
      break;
    case '7':
      if ((estado === 13) && (['3'].includes(perfil))) { visible = true; }
      break;
    case '50':
      if ((estado === 10) && (['4'].includes(perfil))) { visible = true; }
      break;
    default:
      break;
  }
  return visible;
}

export const getBusinessDaysAgo = (daysAgo = 0) => {
  let currentDate = moment();
  const curretDateNoChange = moment();
  let daysRemaining = daysAgo;
  while (daysRemaining > 0) {
    currentDate = currentDate.subtract(1, 'days');
    if (currentDate.weekday() < 5) {
      daysRemaining--;
    }
  }
  //aca voy a valiar que la fecha No sea de un mes distinto al actual
  if (currentDate._d.getMonth() !== curretDateNoChange._d.getMonth()) {
    //en caso de ser distinto devuelvo el primer dia del mes...
    return moment(curretDateNoChange).startOf('month');
  }

  return currentDate;
}

export const returnAnio = fecha => (new Date(fecha)).getFullYear();

export const formatRUT = e => {
  const str = e.replace(/[.-]/g, '');
  const formateado = new Intl.NumberFormat("de-DE").format(str.slice(0, -1).replace(',', '.'))
  return `${formateado}-${str.slice(-1)}`;
}

export const evaluarEstadoBadge = (estado) => {
  let badge = {};
  switch (estado) {
    case 1:
      badge = <Badge pill bg="success">Exitoso</Badge>;
      break;
    case 2:
      badge = <Badge pill bg="danger">Fallido</Badge>;
      break;
    default:
      badge = <Badge pill bg="danger">Fallido</Badge>;
      break;
  }
  return badge;
}

export const capitalizeFirstLetter = (str) => {
  return str != null ? str.split(' ')
    // Transformar cada palabra para que su primera letra sea mayúscula y el resto minúscula
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    // Unir las palabras transformadas de nuevo en un string
    .join(' ') : 'No informado';
}

export function formatearYExtraerCrearColaborador(xmlString) {
  // Parsear el XML
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "application/xml");

  // Buscar el elemento CrearColaborador
  const crearColaborador = xmlDoc.getElementsByTagName("CrearColaborador")[0];
  const resultado = [];

  if (crearColaborador) {
    Array.from(crearColaborador.children).forEach((elemento, index) => {
      // Envolver el nombre del nodo en un elemento <strong> para aplicar el efecto de negrita
      resultado.push(
        <span key={index}>
          <strong>{elemento.nodeName}</strong>: {elemento.textContent ? elemento.textContent : "N/A"}
        </span>
      );

      // Añadir un <br /> después de cada elemento, excepto el último
      if (index < crearColaborador.children.length - 1) {
        resultado.push(<br key={`br-${index}`} />);
      }
    });
  } else {
    resultado.push("Elemento <CrearColaborador> no encontrado.");
  }

  return resultado;
}