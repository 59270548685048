import { obtenerListaVacantes, aprobarSolicitudVacanteApi, declinarSolicitudVacanteApi } from '../api/contratacion/solicitudVacanteApi';
import { faEye, faMinusCircle, faCheckCircle, faTimesCircle, faPencil } from '@fortawesome/free-solid-svg-icons';
import { BACKDROP, CUSTOM_CLASS, customStyles, objNoSuccess, objStyleCol, txtUnProblema } from '../utils/Constants';
import { obtenerDatosPersonaSolicitanteAction } from '../redux/datosPersonaSolicitanteDucks';
import { mensajeCompleto, mensajeSimple, modalCargando } from './ui/sweetAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { cerrarSession, evaluarEstado, formatRUT } from '../utils/funciones';
import DataTable from 'react-data-table-component';
import { momentFecha } from '../utils/funciones';
import { useDispatch } from 'react-redux';
import { get, getUno, post } from '../api/apis';
import Spinner from './ui/Spinner';
import Swal from "sweetalert2";
import '../css/app.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

const ButtonsAcciones = ({ obj, accionRechazar, acccionVerVacante, accionAprobar, accionDeclinar, state }) => {
  const navigate = useNavigate();
  const idPerfil = localStorage.getItem('id_perfil');

  return (
    <div className='d-flex justify-content-center'>
      <button
        className="btn btnAccion rounded-3 btn-primary btn-sm"
        data-bs-toggle="tooltip"
        title="Ver solicitud"
        onClick={() => { acccionVerVacante(obj.id, obj.id_persona, obj, obj.id_usuario_creador) }}
      >
        <FontAwesomeIcon icon={faEye} title='Ver solicitud' />
      </button>

      {evaluarEstado(obj.id_estado_solicitud, idPerfil, '4') &&
        <button
          disabled={(obj.estado_actual === 'Por enviar a SAP') || (obj.estado_actual === 'Enviado a SAP')}
          className="btn btnAccion rounded-3 btn-danger btn-sm"
          data-bs-toggle="tooltip"
          title="Declinar solicitud"
          onClick={() => accionDeclinar(obj.id, obj)}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      }

      {evaluarEstado(obj.id_estado_solicitud, idPerfil, '5') &&
        <button
          className="btn btnAccion btn-info btn-sm rounded-3"
          data-bs-toggle="tooltip"
          title="Devolución por correcciones"
          onClick={() => accionRechazar(obj.id)}
        >
          <FontAwesomeIcon icon={faMinusCircle} color='white' />
        </button>
      }

      {evaluarEstado(obj.id_estado_solicitud, idPerfil, '2') &&
        <button
          disabled={(obj.estado_actual === 'Por enviar a SAP') || (obj.estado_actual === 'Enviado a SAP')}
          className="btn btnAccion rounded-3 btn-success btn-sm"
          data-bs-toggle="tooltip"
          title="Aprobar solicitud"
          onClick={() => accionAprobar(obj.id)}
        >
          <FontAwesomeIcon icon={faCheckCircle} />
        </button>
      }

      {evaluarEstado(obj.id_estado_solicitud, idPerfil, '50') &&
        <button
          className="btn btnAccion btn-info btn-sm rounded-3"
          data-bs-toggle="tooltip"
          title="Editar contratacion"
          onClick={() => {
            navigate(
              `/editar-vacante/${obj.id}`,
              { state: { idSolicitud: obj.id, idPersonaSolicitud: obj.id_persona, solicitud: obj } }
            )
          }}
        >
          <FontAwesomeIcon icon={faPencil} color='white' />
        </button>
      }
    </div>
  )
}

const ListadoVacantesScreen = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [nroPagina, setNroPagina] = useState(1);
  const [pageTable, setPageTable] = useState(1);
  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    // mensajeCompleto('success', 'Solicitud Rechazada Parcialmente', 'Motivo: este es un texto de prueba asdfgadsfas dfasdf sdfdefgerwstre erwtwerte', 5000)
    fetchUsers(pageTable);
  }, []);

  const ingresarDatosOcupante = async (rut, idSolicitud) => {
    Swal.fire({
      title: 'ingresando....',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    await dispatch(obtenerDatosPersonaSolicitanteAction(rut, idSolicitud))
    Swal.close()
    navigate("/formulario-contratacion")

  }


  const getEstadoActual = (solicitud) => {
    return (
      solicitud.id_estado_solicitud === 8 && localStorage.getItem('id_perfil') === '3' ? 'Evaluación Procesos & Remuneraciones' : solicitud.estado_actual
    )
  }

  const fetchUsers = async (page, newPerPage = 10) => {
    setLoading(true);
    const data = await getUno('solicitudproceso', { page: page, total_page: newPerPage });

    if (objNoSuccess[data.status]) {
      mensajeSimple('error', objNoSuccess[data]);
      if ([422, 401].includes(data)) {
        setTimeout(() => { cerrarSession(); }, 1500);
      }
    }

    setLoading(false);

    if ([200].includes(data.status)) {
      try {
        const dataTable = data.data.data.map(el => {
          return {
            id: el.id,
            nombre: el.nombre,
            identificacion: el.identificacion,
            nombre_vacante: el.nombre_vacante,
            fecha_ingreso: el.fecha_ingreso,
            id_estado_solicitud: el.id_estado_solicitud,
            estado_actual: getEstadoActual(el),
            responsable: el.responsable,
            observacion: el.observacion,
            unidad_organizativa: el.data_posicion.MOES_NOMBRE_UNIDAD_ORGANIZATIV,
            acciones: el
          }
        });
        setData(dataTable);
        setTotalRows(data.data.paginacion ? data.data.paginacion.total : 0);
      } catch (error) {
        console.log("error al traer data", error)
        if (page > 1){
          window.location.reload();
        }
      }
    }

  }

  const handlePageChange = page => {
    setPageTable(page)
    fetchUsers(page);
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    fetchUsers(page, newPerPage)
  }

  const returnFecha = fecha => fecha !== 'N/A' ? momentFecha(fecha) : fecha;

  const columnas = [
    {
      name: 'Nuevo colaborador',
      sortable: false,
      selector: row => row.nombre,
      minWidth: "200px",
    },
    {
      name: 'Rut',
      sortable: false,
      selector: row => formatRUT(row.identificacion),
      style: objStyleCol,
      width: '90px',
    },
    {
      name: 'Cargo',
      sortable: false,
      selector: row => row.nombre_vacante,
      width: '250px',
    },
    {
      name: 'Instalación / Unidad',
      sortable: false,
      selector: row => row.unidad_organizativa,
      minWidth: '120px'
    },
    {
      name: 'Fecha Ingreso',
      selector: row => returnFecha(row.fecha_ingreso),
      width: '90px',
      style: objStyleCol
    },
    {
      name: 'Estado actual',
      selector: row => renderEstadoActual(row),
      wrap: true,
      minWidth: '200px'
    },
    {
      name: 'Observacion',
      selector: row => row.observacion,
      wrap: true,
      minWidth: '200px'
    },
    {
      name: 'Acciones',
      sortable: false,
      cell: row => {
        return (
          <ButtonsAcciones
            obj={row.acciones}
            accionRechazar={accionRechazar}
            acccionVerVacante={acccionVerVacante}
            accionAprobar={accionAprobar}
            accionDeclinar={accionDeclinar}
          />
        )
      },
      width: '140px',
      style: {
        display: 'flex'
      }
    },
  ];

  const renderEstadoActual = (row) => {
    const cellStyle = { cursor: 'pointer' };
    if (row.id_estado_solicitud === 18) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${row.id_estado_solicitud}`}>
            <span>
              <p>No se encontró información de Procesos & Remuneraciones. Favor validar con el área correspondiente.</p>
            </span>

          </Tooltip>}
        >
          <div style={cellStyle}>{row.estado_actual}</div>
        </OverlayTrigger>
      );
    } else if (row.id_estado_solicitud === 16) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${row.id_estado_solicitud}`}>{`${row.estado_actual} por ${row.responsable}`}</Tooltip>}
        >
          <div style={cellStyle}>{`${row.estado_actual} por ${row.responsable}`}</div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div>{row.estado_actual}</div>
      );
    }
  };

  const getColumnas = () => {
    const perfil = localStorage.getItem('id_perfil')
    if (perfil === '4') {
      return columnas
    } else {
      return columnas.filter((el, indx) => indx !== 6)
    }
  }

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const accionAprobar = (vacante) => {
    Swal.fire({
      title: '¿Está seguro(a) de aprobar este ingreso de personal?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aprobar',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: 'red',
      showLoaderOnConfirm: true,
      reverseButtons: false,
      customClass: {
        container: 'containerMensajeSimple',
        title: 'titleMensajeSimple',
        text: 'txtLabellInput',
        popup: `popUpSweetCancelarEnviar`,
        confirmButton: 'btnCopec me-4',
        cancelButton: 'btnAtras',
      },
      buttonsStyling: false,
      backdrop: '#395c9d5c left top no-repeat',
      preConfirm: () => {
        return aprobarSolicitudVacanteApi(vacante)
          .then(response => {
            if (response.status !== 'success') {
              throw new Error("No fue posible ingresar la solicitud")
            }
            return response
          })
          .catch(error => {
            Swal.showValidationMessage(
              `No fue posible ingresar la solicitud`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.status === "success") {
          mensajeSimple('success', 'Solicitud Aceptada!');
          fetchUsers(1); // ir a la primera pagina
        } else {
          Swal.fire('Existe un error al aprobar la vacante!', '', 'info')
        }
      }
    })

  }
  const accionDeclinar = async (idVacante, objSolicitud) => {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Motivo para declinar',
      inputPlaceholder: 'Escribe tu motivo...',
      inputAttributes: { 'aria-label': 'Type your message here' },
      reverseButtons: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Declinar',
      customClass: CUSTOM_CLASS,
      backdrop: BACKDROP,
      showCancelButton: true,
      allowOutsideClick: false,
      buttonsStyling: false
    });

    if (text) {
      Swal.fire({
        title: '¿Estas seguro de declinar la vacante?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Declinar',
        cancelButtonText: 'Cancelar',
        customClass: CUSTOM_CLASS,
        showLoaderOnConfirm: true,
        backdrop: BACKDROP,
        buttonsStyling: false,
        reverseButtons: false,
        preConfirm: () => {
          return declinarSolicitudVacanteApi(idVacante, text).then(async (response) => {
            if (response.status === 'error') { throw new Error('Ocurrio un error al momento de realizar esta acción.') }
            return response
          }).catch(() => mensajeSimple('error', 'Ocurrio un error al momento de realizar esta acción', 4000))
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (result.value.status === 'success') {
            const { data_posicion, fecha_ingreso } = objSolicitud;
            modalCargando("Liberando posición...");
            const resp = await post('desbloqueoposicion', { codPosicion: data_posicion.MOES_COD_POSICION, fechaInicio: fecha_ingreso });
            debugger
            if (resp.data.status === 'info') { mensajeSimple('error', 'La posición no pudo ser liberada.', 2500); }
            if (resp.data.status === 'success') {
              mensajeSimple(resp.data.status, resp.data.message, 4000);
              setTimeout(() => fetchUsers(1), 4000);
            }
          }
          if (result.value.status === 'info') { mensajeSimple('error', 'No se pudo declinar.', 2500); }
        } else if (result.isDenied) {
          Swal.fire('No se declinó la vacante', '', 'info')
        }
      })
    }
  }
  const accionRechazar = async (idVacante) => {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Motivo de devolución',
      inputPlaceholder: 'Motivo...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true,
      confirmButtonText: "Devolver",
      cancelButtonText: `Cancelar`,
      customClass: {
        container: 'containerMensajeSimple',
        title: 'titleMensajeSimple',
        text: 'txtLabellInput',
        popup: `popUpSweetCancelarEnviar`,
        confirmButton: 'btnSweetSolo me-4',
        cancelButton: 'btnAtras',
      },
      buttonsStyling: false
    })

    if (text) {
      Swal.fire({
        title: '¿Está seguro que desea devolver temporalmente?',
        icon: 'warning',
        showCancelButton: true,
        customClass: CUSTOM_CLASS,
        confirmButtonText: 'Devolver',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: 'red',
        showLoaderOnConfirm: true,
        buttonsStyling: false,
        preConfirm: () => {
          const body = {
            observaciones: text,
            rechazo: false,
            rechazo_temporal: true
          }

          return aprobarSolicitudVacanteApi(idVacante, body)
            .then(response => {
              if (response.status !== 'success') {
                throw new Error("No fue posible ingresar la solicitud")
              }
              return response
            })
            .catch(error => {
              mensajeSimple('info', txtUnProblema, 2000)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.status === "success") {
            mensajeCompleto('success', '¡Solicitud Rechazada Parcialmente!', `Motivo: ${text}`, 4000);
            setTimeout(() => {
              fetchUsers(pageTable);
            }, 3700);
          } else {
            mensajeSimple('info', txtUnProblema, 2000)
          }
        }
      })

    }
  }
  const acccionVerVacante = async (idSolicitud, idPersonaSolicitud, solicitud, id_usuario_creador) => {
    return navigate(`/vacante/${idSolicitud}`, { state: { idSolicitud, idPersonaSolicitud, solicitud, editable: true, id_usuario_creador:id_usuario_creador  } });
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 ">
          <div className="card">
            <div className="card-header bgArcopime h5 py-1">Estado de Solicitudes en Curso</div>
            {
              loading
                ? (<Spinner />)
                : (
                  <div className="card-body contenedorTabla">
                    {data.length > 0
                      ?
                      (
                        <DataTable
                          title={false}
                          responsive={true}
                          columns={getColumnas()}
                          data={data}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationDefaultPage={pageTable}
                          paginationPerPage={perPage}
                          customStyles={customStyles}
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          paginationComponentOptions={paginationComponentOptions}
                          dense
                          fixedHeader
                          fixedHeaderScrollHeight="440px"
                        />
                      )
                      :
                      <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                    }
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ListadoVacantesScreen;
