import React, { useEffect, useLayoutEffect } from "react";
import { obtenerBancosAction, obtenerTipoDeCuentaBanco } from "../../redux/datosBancariosDucks";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import ErrorInput from "../ui/ErrorInput";

const FormDatosBancarios = ({ formik }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = () => {
      dispatch(obtenerBancosAction());
      dispatch(obtenerTipoDeCuentaBanco());
    };
    fetchData();
  }, [dispatch]);

  function traeRut(rut) { return rut.indexOf('-') !== -1 ? rut.split('-')[0] : rut; }

  useEffect(() => {
 
    if (formik.values.datosBancarios.formaDePago === '1') {
      let rutObtenido = personSolicitante.dataPersona.rut === ""
        ? traeRut(formik.values.datosPersonales.rut || localStorage.getItem('rut'))
        : personSolicitante.dataPersona.rut.split('-')[0];
        
      formik.values.datosBancarios.numeroCuenta = rutObtenido
 
      formik.values.datosBancarios.tipoCuenta = "1";
      formik.values.datosBancarios.banco = "4";
    } else if(formik.values.datosBancarios.formaDePago === '3') {
      formik.values.datosBancarios.numeroCuenta = 0;
      formik.values.datosBancarios.tipoCuenta = 0;
      formik.values.datosBancarios.banco = 0;
    }
    else {
      formik.values.datosBancarios.numeroCuenta = formik.values.datosBancarios.numeroCuenta ? formik.values.datosBancarios.numeroCuenta  : '';
      formik.values.datosBancarios.tipoCuenta = formik.values.datosBancarios.tipoCuenta ? formik.values.datosBancarios.tipoCuenta  : '';
      formik.values.datosBancarios.banco = formik.values.datosBancarios.banco ? formik.values.datosBancarios.banco  : '';
    }
  }, [formik.values.datosBancarios.formaDePago])



  const bancos = useSelector((store) => store.datosBancarios.bancos);
  const tipoCuentasBancarias = useSelector(
    (store) => store.datosBancarios.tipoCuentas
  );

  const personSolicitante = useSelector(store => store.datosPersonaSolicitante);
  return (
    <>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="formaDePago">Forma de pago *</label>
          <select
            className="form-select"
            name="datosBancarios.formaDePago"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosBancarios.formaDePago}
          >
            
            <option value="">Seleccione</option>
            <option value={1}>Cuenta Rut</option>
            <option value={2}>Transferencia Bancaria</option>
            <option value={3}>Servipag</option>
          </select>
          {
            formik.touched.datosBancarios && formik.errors.datosBancarios && (
              <ErrorInput
                touched={formik.touched.datosBancarios.formaDePago}
                error={formik.errors.datosBancarios.formaDePago}
              />
            )
          }
        </div>
        {
          parseInt(formik.values.datosBancarios.formaDePago) === 1 && (
            <div className="col-12 col-sm-6 my-1">
              <label htmlFor="numeroCuenta">Número *</label>
              <input
                type="number"
                name="datosBancarios.numeroCuenta"
                className="form-control"
                readOnly={parseInt(formik.values.datosBancarios.formaDePago) === 1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.datosBancarios.numeroCuenta}
              />
              <ErrorInput
                touched={formik.touched.numeroCuenta}
                error={formik.errors.numeroCuenta}
              />
            </div>
          )
        }
        {
          parseInt(formik.values.datosBancarios.formaDePago) === 2 && (
            <div className="col-12 col-sm-6 my-1">
              <label htmlFor="banco">Banco *</label>
              <select
                className="form-select"
                id="banco"
                name="datosBancarios.banco"
                aria-label="Default select example"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.datosBancarios.banco}
              >
                <option value="">Seleccione</option>
                {bancos.map((banco) => (
                  <option key={banco.id} value={banco.id}>
                    {banco.nombre}
                  </option>
                ))}
              </select>
              {
                formik.touched.datosBancarios && formik.errors.datosBancarios && (
                  <ErrorInput
                    touched={formik.touched.datosBancarios.banco}
                    error={formik.errors.datosBancarios.banco}
                  />
                )
              }
            </div>
          )
        }
      </div>

      {
        parseInt(formik.values.datosBancarios.formaDePago) === 2 && (
          <div className="row mt-3">
            <div className="col-12 col-sm-6 my-1">
              <label htmlFor="tipoCuenta">Tipo de cuenta *</label>
              <select
                className="form-select"
                name="datosBancarios.tipoCuenta"
                aria-label="Default select example"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.datosBancarios.tipoCuenta}
              >
                <option value="">Seleccione</option>
                {tipoCuentasBancarias &&
                  tipoCuentasBancarias.map((tipoCuenta) => (
                    <option key={tipoCuenta.id} value={tipoCuenta.id}>
                      {tipoCuenta.nombre}
                    </option>
                  ))}
              </select>
              {
                formik.touched.datosBancarios && formik.errors.datosBancarios && (
                  <ErrorInput
                    touched={formik.touched.datosBancarios.tipoCuenta}
                    error={formik.errors.datosBancarios.tipoCuenta}
                  />
                )
              }
            </div>
            <div className="col-12 col-sm-6 my-1">
              <label htmlFor="numeroCuenta">Número de cuenta *</label>
              <NumberFormat
                name="datosBancarios.numeroCuenta"
                readOnly={formik.values.datosBancarios.formaDePago === 1}
                value={formik.values.datosBancarios.numeroCuenta}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ paddingLeft: '10px' }}
                decimalSeparator={false}
                format="####################"
                autoComplete="Nope"
                allowNegative={false}
                className="form-control"
              />
              {
                formik.touched.datosBancarios && formik.errors.datosBancarios && (
                  <ErrorInput
                    touched={formik.touched.datosBancarios.numeroCuenta}
                    error={formik.errors.datosBancarios.numeroCuenta}
                  />
                )
              }
            </div>
          </div>
        )
      }
    </>
  );
};

export default FormDatosBancarios;
