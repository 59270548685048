import { get } from "../api/apis";
import { BASE_URL_PRODUCCION } from "../utils/Constants";

//constantes 
const OBTENER_GENEROS_EXITO = 'OBTENER_GENEROS_EXITO'

const OBTENER_ESTADO_CIVIL_EXITO = 'OBTENER_ESTADO_CIVIL_EXITO'

const OBTENER_RUT_PERSONA_EXITO = 'OBTENER_RUT_PERSONA_EXITO'

const dataInicial = {
    generos: [],
    estadosCiviles: [],
}

// reducer
export default function datosPersonalesReducer(state = dataInicial, action) {
    switch (action.type) {
        case OBTENER_GENEROS_EXITO:
            return { ...state, generos: action.payload }
        case OBTENER_ESTADO_CIVIL_EXITO:
            return { ...state, estadosCiviles: action.payload }
        default:
            return state;
    }
}

// acciones 

// URL para el consumo de api
const BASE_URL = BASE_URL_PRODUCCION;   

const getParams = () => ({ headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });

export const obtenerGenerosAction = () => async (dispatch, getState) => {
    try {
        const params = getParams();
        const url = `${BASE_URL}/listado/genero`;
        const response = await fetch(url, params);
        const result = await response.json();
        
        dispatch({
            type: OBTENER_GENEROS_EXITO,
            payload: result.data
        })
    } catch (error) {
        console.log("error :", error);
    }
}

export const obtenerEstadosCivilesAction = () => async (dispatch, getState) => {
    try {
        const params = getParams();
        const url = `${BASE_URL}/listado/estadocivil`;
        const response = await fetch(url, params);
        const result = await response.json();

        dispatch({
            type: OBTENER_ESTADO_CIVIL_EXITO,
            payload: result.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}

export const obtenerRutAction = (rut) => async (dispatch, getState) => {
    try {
        const params = getParams();
        const url = `${BASE_URL} /verificarpostulante?RutPersona=${rut}`
        const res = await fetch(url, params)
        const resJSON = res.json()
        dispatch({
            type: OBTENER_RUT_PERSONA_EXITO,
            payload: resJSON.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}

