import jwtDecode from "jwt-decode"
import { BASE_URL_PRODUCCION } from "../../utils/Constants";
import axios from "axios";

export const guardarDatosLaboralesApi = async (data, idSolicitud, metodo = "POST") => {
  try {
    const url = `${BASE_URL_PRODUCCION}/vacante`
    const { cargo, posicion, planHorario, unidadOrganizativaRolTemporis, cargoRolTemporis, tipoContrato, fechaFinContrato, fechaIngreso } = data
    const cargoFormateado = JSON.parse(cargo)
    const planHorarioFormateado = planHorario

    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: metodo,
      body: JSON.stringify({
        data_posicion: cargoFormateado,
        tipo_contrato: tipoContrato,
        fecha_ingreso: fechaIngreso,
        fecha_retiro: fechaFinContrato,
        temporis_plan_horario: {
          data: planHorarioFormateado
        },
        temporis_unidad_rol: {
          data: unidadOrganizativaRolTemporis
        },
        id_solicitud: idSolicitud
      })
    }
    const res = await fetch(url, params);
    const resJSON = await res.json()
    return resJSON;

  } catch (error) {
    console.log("Error: ", error)
  }
}

export const obtenerPosicionesLaboralesApi = async (id) => {
  try {
    const resp = await axios({
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, },
      data: { "cod_division": id },
      url: `${BASE_URL_PRODUCCION}/posiciones`,
    });
    return resp.data;

  } catch (error) {
    console.log("Error :", error)
  }
}

export const obtenerDatosLaboralesApi = async () => {
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/posiciones`
    const res = await fetch(url, params)
    const resJSON = await res.json()
    return resJSON
  } catch (error) {
    console.log("Error :", error)
  }
}