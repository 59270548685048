import { BASE_URL_PRODUCCION } from "../../utils/Constants"

export const guardarDatosPersonalesApi = async (datosPersonales, metodo = "POST") => {
  try {
    const url = `${BASE_URL_PRODUCCION}/persona`;
    const { rut, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento, sexo, estadoCivil, nivelFormacion, nacionalidad, email, telefonoCelular, id_persona } = datosPersonales;

    const params = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: metodo,
      body: JSON.stringify({
        nombre: nombres,
        apellido_pat: apellidoPaterno,
        apellido_mat: apellidoMaterno,
        identificacion: rut,
        fecha_nacimiento: fechaNacimiento,
        correo: email,
        telefono: telefonoCelular,
        id_persona_genero: sexo,
        id_persona_estado_civil: estadoCivil,
        id_educacion_nivel: nivelFormacion,
        id_pais: nacionalidad,
        idPersona: id_persona
      })
    };

    let res = await fetch(url, params);
    let resJSON = await res.json()
    if (resJSON.hasOwnProperty('existe')) {
      resJSON = await actualizarDatosPersonalesApi(resJSON.id_persona, datosPersonales)
    }
    return resJSON
  } catch (error) {
    console.log("Error : ", error)
  }
}

export const obtenerDatosPersonalesApi = async idPersona => {
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/persona?idPersona=${idPersona}`
    const res = await fetch(url, params)
    const resJSON = await res.json()

    return resJSON
  } catch (error) {
    console.log("Error :", error)
  }

}

export const actualizarDatosPersonalesApi = async (idPersona, datosPersonales) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/persona?idPersona=${idPersona}`;

    const { rut, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento, sexo, estadoCivil, nivelFormacion, nacionalidad, email, telefonoCelular } = datosPersonales;

    const params = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: "PUT",
      body: JSON.stringify({
        nombre: nombres,
        apellido_pat: apellidoPaterno,
        apellido_mat: apellidoMaterno,
        identificacion: rut,
        fecha_nacimiento: fechaNacimiento,
        correo: email,
        telefono: telefonoCelular,
        id_persona_genero: sexo,
        id_persona_estado_civil: estadoCivil,
        id_educacion_nivel: nivelFormacion,
        id_pais: nacionalidad
      })
    };

    const res = await fetch(url, params);
    const resJSON = await res.json()

    return resJSON
  } catch (error) {

  }
}