import { useEffect } from "react";
import { momentFecha } from "../../utils/funciones";

export const CardDatosLaborales = ({ props }) => {
  const {
    fecha_retiro, codigo_sap, nombre_vacante, tipo_contrato, temporis_unidad_rol,
    inicio_solicitud, fecha_ingreso, temporis_plan_horario, nombre_local
  } = props.datosLaborales;

  return (
    <div className="card my-3 px-0">
      <div className="card-header bgArcopime"><label className='h6 my-0'>Datos laborales</label></div>
      <div className="card-body">
        <div className="row">
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Cargo a Ocupar:</span> {`${codigo_sap}  ${nombre_vacante}`}
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel me-1'>Tipo contrato:</span>
            {
              (tipo_contrato === 1 && ("Plazo Fijo 30 Días")) ||
              (tipo_contrato === 2 && ("Plazo Fijo 60 Días")) ||
              (tipo_contrato === 3 && ("Plazo Fijo 90 Días")) ||
              (tipo_contrato === 4 && ("Indefinido"))
            }
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Unidad de temporis:</span> {
              temporis_unidad_rol.data !== ''
                ? JSON.parse(temporis_unidad_rol.data).Unidadorganizativa
                : 'No aplica'
            }
          </p>
        </div>
        <div className="row">
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Inicio solicitud:</span> {inicio_solicitud}
          </p>
          <p className='col-4 mb-1'><span className='tagLabel'>Ingreso:</span> {momentFecha(fecha_ingreso)}</p>
          <p className='col-4 mb-1'>
            <span className='tagLabel me-1'>Término:</span>
            {tipo_contrato !== 4 ? momentFecha(fecha_retiro) : 'Plazo indefinido'}
          </p>
        </div>
        <div className="row">
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Horario de temporis:</span> {temporis_plan_horario}
          </p>
          <p className='col-8 mb-1'>
            <span className='tagLabel me-1'>Nombre local:</span>
            {nombre_local || 'No aplica'}
          </p>
        </div>
      </div>
    </div>
  )
}

export const CardDatosPeronales = ({ props }) => {
  const { datosPersonales, filtrarGradoEducacion, filtrarEstadoCivil, filtrarSexo, filtrarPaisPersona } = props;

  return (
    <div className="card my-3 px-0">
      <div className="card-header bgArcopime"><label className='h6 my-0'>Datos personales</label></div>
      <div className="card-body">
        <div className="row">
          <p className="col-4 mb-1">
            <span className='tagLabel'>Nombre:</span> <span className='text-capitalize'>{datosPersonales.nombre}</span>
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Fecha de nacimiento:</span> {momentFecha(datosPersonales.fecha_nacimiento)}
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Email:</span> {datosPersonales.correo}
          </p>
        </div>
        <div className="row">
          <p className="col-4 mb-1">
            <span className='tagLabel'>Apellido paterno:</span> <span className='text-capitalize'>{datosPersonales.apellido_pat}</span>
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Grado de educación:</span> {filtrarGradoEducacion}
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Teléfono:</span>

            <span className="mx-1">+569</span> {datosPersonales.telefono}
          </p>
        </div>
        <div className="row">
          <p className="col-4 mb-1">
            <span className='tagLabel'>Apellido materno:</span> <span className="text-capitalize">{datosPersonales.apellido_mat || 'No aplica'}</span>
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Estado civil:</span> {filtrarEstadoCivil}
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>Género:</span> {filtrarSexo}
          </p>
        </div>
        <div className="row">
          <p className="col-4 mb-1">
            <span className='tagLabel'>Rut:</span> {datosPersonales.identificacion}
          </p>
          <p className='col-4 mb-1'>
            <span className='tagLabel'>País:</span> {filtrarPaisPersona}
          </p>
        </div>
      </div>
    </div>
  )
}

export const CardDatosExtranjero = ({ props }) => {
  const { id_persona_tipo_visa, fecha_visa_temporaria, fecha_vencimiento_visa } = props.datosExtranjero;

  return (
    <div className="card my-3 px-0">
      <div className="card-header bgArcopime"><label className='h6 my-0'>Datos extranjería</label></div>
      <div className="card-body">
        <div className="row">
          <p className="col-4">
            <span className='tagLabel'>Tipo de visa:</span> {id_persona_tipo_visa === 1 ? "Definitiva" : "Temporaria"}
          </p>
          <p className="col-4">
            <span className='tagLabel me-1'>Fecha emisión:</span>{momentFecha(fecha_visa_temporaria)}
          </p>
          <p className="col-4">
            <span className='tagLabel me-1'>Fecha vencimiento:</span>{momentFecha(fecha_vencimiento_visa)}
          </p>
        </div>
      </div>
    </div>
  )
}

export const CardDatosDireccion = ({ props }) => {
  const { filtrarPaisDireccion, filtrarRegion, comunas } = props;
  const { direccion, numero, numero_departamento } = props.datosDireccion;

  return (
    <div className="col-4 ps-0">
      <div className="card my-3 px-0">
        <div className="card-header bgArcopime"><label className='h6 my-0'>Dirección</label></div>
        <div className="card-body">
          <div className="row">
            <p className="mb-1">
              <span className='tagLabel'>Dirección:</span> {direccion}
            </p>
            <p className="mb-1">
              <span className='tagLabel'>País:</span> {filtrarPaisDireccion}
            </p>
            <p className="mb-1">
              <span className='tagLabel'>Región:</span> {filtrarRegion}
            </p>
            <p className="mb-1">
              <span className='tagLabel'>Comuna:</span> {comunas}
            </p>
            <p className="mb-1">
              <span className='tagLabel'>Número:</span> {numero || 'No especifíca'}
            </p>
            <p className="mb-1">
              <span className='tagLabel'>Casa/Depto/Parcela:</span> {numero_departamento || 'No especifíca'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CardDatosPrevisionSalud = ({ props }) => {
  const { id, nombre, valorPlan } = props.datoSalud;
  const { jubilado } = props.datosPrevision;
  const { filtrarAfp } = props;

  return (
    <div className="card my-3 px-0">
      <div className="card-header bgArcopime"><label className='h6 my-0'>Datos previsión</label></div>
      <div className="card-body">
        <div className="row">
          <p className="mb-1">
            <span className='tagLabel'>Es una persona jubilada:</span> {jubilado === 1 ? "Si" : "No"}
          </p>
          <p className="mb-1">
            <span className='tagLabel'>Afp:</span> {filtrarAfp}
          </p>
          <p className="mb-1">
            <span className='tagLabel'>{id !== 1 ? 'Isapre' : 'Plan salud'}:</span> {nombre}
          </p>
          <p className="mb-1">
            {id !== 1 &&
              <>
                <span className='tagLabel'>Valor:</span> {valorPlan}
              </>
            }
          </p>
        </div>
      </div>
    </div>
  )
}

export const CardDatosBancarios = ({ props }) => {
  const { tipo_pago, nombre_banco, nombre_tipo_cuenta, numero_cuenta } = props.datosBancarios;

  return (
    <div className="card my-3 px-0">
      <div className="card-header bgArcopime"><label className='h6 my-0'>Datos bancarios</label></div>
      <div className="card-body">
        <div className="row">
          {tipo_pago !== 3 ?
            <>
              <p className="mb-1">
                <span className='tagLabel'>Banco:</span> {nombre_banco}
              </p>
              <p className="mb-1">
                <span className='tagLabel'>Tipo de cuenta:</span> {nombre_tipo_cuenta}
              </p>
              <p className="mb-1">
                <span className='tagLabel'>Número de cuenta:</span> {numero_cuenta}
              </p>
            </>
            :
            <p className="mb-1">
              <span className='tagLabel'>Tipo pago:</span> Servipag
            </p>
          }
        </div>
      </div>
    </div>
  )
}

export const CardDocumentos = ({ props }) => {
  const { datosDocumento } = props;

  return (
    <div className='row'>
      <div className="card my-3 px-0">
        <div className="card-header bgArcopime"><label className='h6 my-0'>Documentos</label></div>
        <div className="card-body">
          <div className='row'>
            {
              datosDocumento.map((documento, indx) => (
                <div className='col-lg-3 col-sm-3' key={indx}>
                  <div className="card border-dark mb-3">
                    <div className="card-header">{documento.documento}</div>
                    <div className="card-body text-dark">
                      <a target="_blank" rel="noreferrer" href={documento.url} className="btn btn-outline-success">ver documento</a>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}