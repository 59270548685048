import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import {
  obtenerComunasAction,
  obtenerRegionesAction,
  obtenerTipoDireccion,
} from "../../redux/datosZonasDucks";
import ErrorInput from "../ui/ErrorInput";

const FormDatosDireccion = ({ formik }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = () => {
      dispatch(obtenerRegionesAction());
      dispatch(obtenerTipoDireccion())
    };
    fetchData();
  }, [dispatch]); 

  const regiones = useSelector((store) => store.datosZonas?.regiones);
  const tipoDireccion = useSelector(store => store.datosZonas.tipoDireccion)

  useEffect(() => {
    const fetchData = () => {
      dispatch(obtenerComunasAction(formik.values.datosDireccion.region));
    };
    if(formik.values.datosDireccion.region) { fetchData(); }
  }, [formik.values.datosDireccion.region, dispatch]);

  const comunas = useSelector((store) => store.datosZonas?.comunas.sort(function (a, b) {
    if (a.nombre.replace('Ñ', 'N') > b.nombre.replace('Ñ', 'N')) {
        return 1;
    }
    if (b.nombre.replace('Ñ', 'N') > a.nombre.replace('Ñ', 'N')) {
        return -1;
    }
    return 0;
}));

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="calle">Tipo de dirección *</label>
          <select
            className="form-select"
            name="datosDireccion.tipoDireccion"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosDireccion.tipoDireccion}
          >
            <option value="">Seleccione</option>
            {
              tipoDireccion && tipoDireccion.map(tipo => (<option value={tipo.id} key={tipo.id}>{tipo.nombre.toUpperCase()}</option>))
            }
          </select>
          {
            formik.touched.datosDireccion && formik.errors.datosDireccion && (
              <ErrorInput
                touched={formik.touched.datosDireccion.tipoDireccion}
                error={formik.errors.datosDireccion.tipoDireccion}
              />
            )
          }
        </div>

        {
          formik.values.datosDireccion.tipoDireccion && (
            <div className="col-12 col-sm-6 my-1">
              <label htmlFor="region">Región *</label>
              <select
                className="form-select"
                name="datosDireccion.region"
                aria-label="Default select example"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.datosDireccion.region}
              >
                <option value="">Seleccione</option>
                {regiones &&
                  regiones.map((region) => (
                    <option key={region.id} value={region.id}>{region.nombre.toUpperCase()}</option>
                  ))}
              </select>
              {
                formik.touched.datosDireccion && formik.errors.datosDireccion && (
                  <ErrorInput
                    touched={formik.touched.datosDireccion.region}
                    error={formik.errors.datosDireccion.region}
                  />
                )
              }
            </div>
          )
        }
      </div>
      {
        formik.values.datosDireccion.tipoDireccion && (
          <>
            <div className="row mt-3">
              <div className="col-12 col-sm-6 my-1">
                <label htmlFor="comuna">Comuna *</label>
                <select
                  className="form-select"
                  name="datosDireccion.comuna"
                  aria-label="Default select example"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.datosDireccion.comuna}
                  style={{textTransform: "capitalize"}}
                >
                  <option value="">Seleccione</option>
                  {comunas &&
                    comunas.map((comuna) => (
                      <option style={{textTransform: "capitalize!important"}} key={comuna.id} value={comuna.id}>{comuna.nombre}</option>
                    ))}
                </select>
                {
                  formik.touched.datosDireccion && formik.errors.datosDireccion && (
                    <ErrorInput
                      touched={formik.touched.datosDireccion.comuna}
                      error={formik.errors.datosDireccion.comuna}
                    />
                  )
                }
              </div>
              <div className="col-12 col-sm-6 my-1">
                <label htmlFor="calle">Calle *</label>
                <input
                  type="text"
                  autoComplete="nope"
                  name="datosDireccion.calle"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.datosDireccion.calle}
                  maxLength="100"
                />
                {
                  formik.touched.datosDireccion && formik.errors.datosDireccion && (
                    <ErrorInput
                      touched={formik.touched.datosDireccion.calle}
                      error={formik.errors.datosDireccion.calle}
                    />
                  )
                }
              </div>
            </div>
            <div className="row mt-3">
              {
                formik.values.datosDireccion.tipoDireccion && (
                  <div className="col-12 col-sm-6 my-1">
                    <label htmlFor="numeroCasa">Número de domicilio *</label>
                    <NumberFormat
                      name="datosDireccion.numeroCasa"
                      autoComplete="nope"
                      value={formik.values.datosDireccion.numeroCasa}
                      placeholder='S/N'
                      onChange={formik.handleChange}
                      style={{paddingLeft:'10px'}}
                      decimalSeparator={false}
                      format="########"
                      allowNegative={false}
                      className="form-control"
                      onBlur={formik.handleBlur}
                    />
                    {
                      formik.touched.datosDireccion && formik.errors.datosDireccion && (
                        <ErrorInput
                          touched={formik.touched.datosDireccion.numeroCasa}
                          error={formik.errors.datosDireccion.numeroCasa}
                        />
                      )
                    }
                  </div>
                )
              }              
              <div className="col-12 col-sm-6 my-1">
                <label htmlFor="numeroDepartamento">Número de departamento <b>/</b> Casa <b>/</b> Condominio <b>/</b> Sitio</label>
                <input
                  type="text"
                  autoComplete="nope"
                  name="datosDireccion.numeroDepartamento"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.datosDireccion.numeroDepartamento}
                  maxLength="5"
                />
              </div>
            </div>
            <div className="row mt-3"></div>
          </>
        )
      }

    </>
  );
};

export default FormDatosDireccion;
