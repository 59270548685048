import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2';

import { guardarDatosPersonaExtranjeraApi } from "../api/contratacion/datosPersonaExtranjeraApi";
import { guardarDatosPersonalesApi } from '../api/contratacion/datosPersonalesApi';
import { guardarDatosPrevisionApi } from '../api/contratacion/datosPrevisionApi';
import { guardarDatosBancariosApi } from '../api/contratacion/datosBancariosApi';
import { DOCUMENTOS, objNoSuccess, const_objPutPost } from '../utils/Constants';
import FormDatosPersonales from './procesoContratacion/FormDatosPersonales';
import { guardarDireccionApi } from '../api/contratacion/datosDireccionApi';
import FormDatosBancarios from './procesoContratacion/FormDatosBancarios';
import FormDatosDireccion from './procesoContratacion/FormDatosDireccion';
import FormDatosPrevision from './procesoContratacion/FormDatosPrevision';
import { guardarDatosSaludApi } from '../api/contratacion/datosSaludApi';
import { guardarDocumentoApi } from '../api/contratacion/documentosApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDocumentos } from './procesoContratacion/useDocumentos';
import FormDocumentos from './procesoContratacion/FormDocumentos';
import { modalCargando, modalComentario, mensajeSimple } from './ui/sweetAlerts';
import { useContratacionScreen } from './useContratacionScreen';
import { SpinnerModal } from './ui/Spinner';
import { HtmlList } from './ui/sweetAlerts';
import { post, getUno } from '../api/apis';


export const EditarContratacionScreen = () => {
  const { state } = useLocation();
  const [visibleSpinner, setVisibleSpinner] = useState(true);
  const [datosExtranjero, setDatosExtranjero] = useState([]);
  const [datosLaborales, setDatosLaborales] = useState([]);
  const [datosDireccion, setDatosDireccion] = useState([]);
  const [datosBancarios, setDatosBancarios] = useState([]);
  const [datosPrevision, setDatosPrevision] = useState([]);
  const [datosDocumento, setdatosDocumento] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [listaGuardado, setListaGuardado] = useState([]);
  const [datosPersona, setDatosPersona] = useState({});
  let objPostPut = const_objPutPost;
  const [guardadoTemporal, setGuardadoTemporal] = useState(false);
  const [datosSalud, setDatosSalud] = useState([]);
  const [txtSpinner, setTxtSpinner] = useState('');
  const { objDocumentos } = useDocumentos();
  const navigate = useNavigate();
  const params = useParams();
  const msjCorrecto = ", guardado correctamente."
  const msjIncorrecto = ", no se pudo guardar."

  const { validate } = useContratacionScreen();

  function setearMensaje(tipo, resp) {
    setListaGuardado(
      (listaGuardado) => [
        ...listaGuardado,
        {
          icono: `${resp.status === 'success' ? 'check.png' : 'warning.png'}`,
          texto: `${tipo}${resp.status === 'success' ? msjCorrecto : msjIncorrecto}`
        }
      ]
    );
  }

  useEffect(() => { obtenerInfo() }, []);
  useEffect(() => {
    setGuardadoTemporal(state.solicitud.guardado_temporal !== 0 ? true : false)
  }, [state]);

  const { idSolicitud } = params;

  const enviarCorreoDatosCompletos = async (idPersona, idSolicitud) => {
    await post('vacante/enviar_temporis', { "id_solicitud": idSolicitud, "idUsuario": idPersona });
  }

  const saveNewDataFrom = async (values, personaSolicitante = false, editaVacante = true, observaciones) => {
    setVisibleModal(!visibleModal);

    setTxtSpinner(() => setTxtSpinner('Datos personales'));
    let resp = await guardarDatosPersonalesApi(values.datosPersonales, objPostPut['persona']);
    setearMensaje('Datos personales', resp);
    const idPersona = resp.data.id_persona;

    if ((values.datosPersonales.nacionalidad).toString() !== '1') {
      setTxtSpinner(() => setTxtSpinner('Datos extranjería'));
      const metodo = !values.datosPersonaExtranjera.idPersonaExtranjera ? 'POST' : 'PUT';
      const respExtranjero = await guardarDatosPersonaExtranjeraApi(values.datosPersonaExtranjera, idPersona, metodo);
      setearMensaje('Datos extranjería', respExtranjero);
    }

    setTxtSpinner(() => setTxtSpinner('Datos bancarios'));

    resp = await guardarDatosBancariosApi(values.datosBancarios, idPersona, objPostPut['personadatosbancarios']);
    setearMensaje('Datos bancarios', resp);

    setTxtSpinner(() => setTxtSpinner('Datos previsión'));
    resp = await guardarDatosPrevisionApi(values.datosPrevision, idPersona, objPostPut['personaprevision']);
    setearMensaje('Datos previsión', resp);

    setTxtSpinner(() => setTxtSpinner('Datos salud'));
    resp = await guardarDatosSaludApi(values.datosPrevision, idPersona, objPostPut['personasalud']);
    setearMensaje('Datos salud', resp);

    setTxtSpinner(() => setTxtSpinner('Datos dirección'));
    resp = await guardarDireccionApi(values.datosDireccion, idPersona, objPostPut['personadireccion']);
    setearMensaje('Datos dirección', resp);
    const dato = values.datosDocumentos;

    await Promise.all(DOCUMENTOS.map(async (dataDocumento) => {
      if (typeof (values.datosDocumentos[dataDocumento.documento]) === 'object') {
        setTxtSpinner(() => setTxtSpinner('Documento'));
        const respDocumento = await guardarDocumentoApi(values.datosDocumentos[dataDocumento.documento], idPersona, dataDocumento['id_tipo'], 'POST');
        setearMensaje('Documento', respDocumento);
      }
    }));


    if (guardadoTemporal) {
      setTxtSpinner(() => setTxtSpinner('Solicitud'));

      const resp = await post('solicitud', null, {
        id_persona: idPersona,
        guardado_temporal: 0,
        trabajador_nuevo: datosPersona.reingreso === 1 ? 0 : 1, // se refiere a si el proceso ya se encuentra en proceso o se esta ingresando los datos de un trabjador nuevo sin necesidad de ser aprobado por rrhh 
        cod_division: datosLaborales.cod_division,
      });
      enviarCorreoDatosCompletos(idPersona, idSolicitud);
      setearMensaje('Solicitud', resp.data);
    }

    // Para la vista de editar, si le llega la solicitud al perfil 4 con guardado temporal falso, significa que RRHH devolvió la solicitud realizada para correcciones
    if (localStorage.getItem('id_perfil') === '4' && guardadoTemporal === false) {
      setTxtSpinner(() => setTxtSpinner('Enviando reconsideración'));
      const respConsideracion = await post('envioreconsideracion', { idSolicitud: state.idSolicitud }, { observaciones: observaciones });

      if (respConsideracion.data.status === 'success') {
        setearMensaje('Reconsideración', respConsideracion.data);
      }
    }

    // Si el perfil es RRHH en esta vista, significa que RRHH está realizando modificaciones en la solicitud, por lo que debe haber log de los cambios realizados
    if (localStorage.getItem('id_perfil') === '3') {
      // Se setea el log de los cambios realizados por RRHH par que quede registrado
      let log = {
        observaciones: observaciones,
        rechazo: 0,
        estado: 1,
        id_solicitud: idSolicitud,
        id_solicitud_estado: state.solicitud.id_estado_solicitud, // revisar porque debería quedar el estado actual de la solicitud
        id_usuario_creador: localStorage.getItem('user_id'),
        rechazo_temporal: 0,
        created_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
      }
      try {
        await post('solicitudprocesolog', {}, log);
      } catch (error) {
        console.log(error);
      }
    }

    setVisibleSpinner(!visibleSpinner);
  }

  const evaluaCorreoActivo = async (email) => {
    modalCargando('Verificando email...');
    const resp = await post('verificacorreousuarioactivo', "", { email: email })
    if (resp.data.status === 'info') {
      mensajeSimple('error', resp.data.message, 2500)
      return false
    }
    mensajeSimple('success', "Email permitido", 1500)
    return true
  }

  const Preguardadotemporal = async (formik) => {
    if (!await evaluaCorreoActivo(formik.datosPersonales.email)) {
      return
    }
    saveNewDataFrom(formik)
  }

  const setResp = (resp, ejec, llave) => {
    if (!objNoSuccess[resp.status]) {
      ejec(resp.data.data);
      objPostPut[llave] = "PUT";
      return
    }
    objPostPut[llave] = "POST";
  }

  const obtenerInfo = async () => {
    modalCargando('Obteniendo datos...');
    const objParams = { idPersona: state.idPersonaSolicitud, idSolicitud: state.idSolicitud }

    let resp = await getUno('persona', { idPersona: objParams.idPersona });
    if (!objNoSuccess[resp.status]) {
      setDatosPersona(resp.data.data);
      objPostPut['persona'] = "PUT";
    };

    if (resp.data.data.id_pais !== 1) {
      resp = await getUno('personaextranjera', objParams);
      setResp(resp, setDatosExtranjero, 'personaextranjera');
    }
    resp = await getUno('vacante', objParams);
    setResp(resp, setDatosLaborales, 'vacante');
    resp = await getUno('personaprevision', { idPersona: objParams.idPersona });
    setResp(resp, setDatosPrevision, 'personaprevision');
    resp = await getUno('personasalud', { idPersona: objParams.idPersona });
    setResp(resp, setDatosSalud, 'personasalud');
    resp = await getUno('personadireccion', { idPersona: objParams.idPersona });
    setResp(resp, setDatosDireccion, 'personadireccion');
    resp = await getUno('personadatosbancarios', { idPersona: objParams.idPersona });
    setResp(resp, setDatosBancarios, 'personadatosbancarios');
    resp = await getUno('documento', { idPersona: objParams.idPersona });
    setResp(resp, setdatosDocumento, 'documento');
    Swal.close();
  }

  const acccionVerVacante = async (idSolicitud, idPersonaSolicitud, solicitud) => {
    return navigate(`/vacante/${idSolicitud}`, { state: { idSolicitud, idPersonaSolicitud, solicitud } });
  }

  const returnValor = val => val ?? '';

  const formik = useFormik({
    enableReinitialize: true,
    validate,
    validateOnMount: true,
    initialValues: {
      datosLaborales: {
        fechaIngreso: returnValor(datosLaborales.fecha_ingreso),
        cargo: returnValor(datosLaborales.nombre_vacante),
        planHorario: returnValor(datosLaborales.temporis_plan_horario),
        unidadOrganizativaRolTemporis: "",
        cargoRolTemporis: "",
        tipoContrato: returnValor(datosLaborales.tipo_contrato),
        fechaFinContrato: returnValor(datosLaborales.fecha_retiro),
      },
      datosPersonales: {
        rut: returnValor(datosPersona.identificacion),
        id_persona: returnValor(datosPersona.id),
        nombres: returnValor(datosPersona.nombre),
        apellidoPaterno: returnValor(datosPersona.apellido_pat),
        apellidoMaterno: returnValor(datosPersona.apellido_mat),
        fechaNacimiento: returnValor(datosPersona.fecha_nacimiento),
        sexo: returnValor(datosPersona.id_persona_genero),
        estadoCivil: returnValor(datosPersona.id_persona_estado_civil),
        nivelFormacion: returnValor(datosPersona.id_educacion_nivel),
        nacionalidad: returnValor(datosPersona.id_pais),
        email: returnValor(datosPersona.correo),
        telefonoCelular: returnValor(datosPersona.telefono),
      },
      datosPersonaExtranjera: {
        tipoVisa: returnValor(datosExtranjero.id_persona_tipo_visa),
        visaTemporariaDesde: returnValor(datosExtranjero.fecha_visa_temporaria),
        visaTemporariaHasta: returnValor(datosExtranjero.fecha_vencimiento_visa),
        idPersonaExtranjera: returnValor(datosExtranjero.id),
      },
      datosDireccion: {
        calle: returnValor(datosDireccion.direccion),
        numeroCasa: returnValor(datosDireccion.numero),
        numeroDepartamento: returnValor(datosDireccion.numero_departamento),
        region: returnValor(datosDireccion.id_region),
        comuna: returnValor(datosDireccion.id_comuna),
        pais: returnValor(datosDireccion.id_pais),
        tipoDireccion: returnValor(datosDireccion.id_tipo_direccion)
      },
      datosPrevision: {
        jubilado: returnValor(datosPrevision?.jubilado?.toString()),
        previsionSalud: returnValor(datosSalud?.id_institucion_salud),
        previsionSocial: returnValor(datosPrevision?.id_afp),
        planPactado: datosSalud?.valor_plan || 0,
        certificadoCovid: datosSalud?.certificaco_covid || 0,
        certificadoDiscapacidad: datosSalud?.certificaco_discapacidad || 0
      },
      datosBancarios: {
        formaDePago: datosBancarios.tipo_pago,
        banco: datosBancarios.id_banco,
        tipoCuenta: datosBancarios.id_tipo_cuenta,
        numeroCuenta: datosBancarios.numero_cuenta
      },
      datosDocumentos: objDocumentos(datosDocumento),
    },
    onSubmit: (values, comentario) => {
      saveNewDataFrom(values, false, false)
    }
  });

  useEffect(() => {
    formik.setErrors(validate(formik.values));
  }, [formik.values]);

  return (
    <>
      <Modal show={visibleModal}>
        <HtmlList lista={listaGuardado} />

        {visibleSpinner && <SpinnerModal txt={txtSpinner} />}

        {!visibleSpinner &&
          <>
            <div className="text-center">
              <button
                className="btnArcoprime mb-4 mt-2"
                onClick={() => {
                  setVisibleSpinner(!visibleSpinner);
                  setVisibleModal(!visibleModal);
                  acccionVerVacante(state.idSolicitud, state.idPersonaSolicitud, state);
                }}
              >Entendido</button>
            </div>
          </>
        }
      </Modal>

      <div className='mx-5 px-5'>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Datos Personales</h3>
          </div>
          <div className="card-body">
            <FormDatosPersonales formik={formik} />
          </div>
        </div>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Dirección</h3>
          </div>
          <div className="card-body">
            <FormDatosDireccion formik={formik} />
          </div>
        </div>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Datos previsión</h3>
          </div>
          <div className="card-body">
            <FormDatosPrevision formik={formik} />
          </div>
        </div>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Datos Bancarios</h3>
          </div>
          <div className="card-body">
            <FormDatosBancarios formik={formik} />
          </div>
        </div>
        <div className="card my-2">
          <div className="card-body">
            <h3>Subir Documentos nuevos</h3>
            <FormDocumentos formik={formik} />
          </div>
        </div>
        <div className="card my-2">
          <div className="card-body">
            <h3>Documentos cargados</h3>
            <div className='row'>
              {
                datosDocumento.map((documento) => (
                  <div className='col-lg-3 col-sm-3'>
                    <div className="card border-dark mb-3">
                      <div className="card-header">{documento.documento}</div>
                      <div className="card-body text-dark">
                        <a target="_blank" rel="noreferrer" href={documento.url} className="btn btn-outline-success">ver documento</a>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end pb-2">
          <button
            className="btn btn-outline-primary me-md-2"
            type="button"
            onClick={() => { navigate(-1) }}
          >Volver</button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {

              let txtTituloModal = 'Guardar';
              // La única forma de que RRHH (perfil 3) esté aqui es que el analista (perfil 4) haya finalizado y enviado la solicitud a RRHH
              if (localStorage.getItem('id_perfil') === '3') {
                txtTituloModal = "Modificar Solicitud"
                // Lógica para guardar los cambios relizados por RRHH
                // Se deja con modalComentario para obtener la observación y hacer el envío generando un log con el cambio de RRHH
                modalComentario(saveNewDataFrom, txtTituloModal, formik.values);
              }

              if (localStorage.getItem('id_perfil') === '4') {
                // Caso 1: Desde la vista "FormContratacionScreen" viene con guardado temporal = true para que el analista la continue y finalice
                if (guardadoTemporal === true) {
                  // Lógica para enviar solicitud a RRHH porque por lógica aplicada solo hay un guardado temporal y la realiza el perfil 4
                  Preguardadotemporal(formik.values);
                } else {
                  txtTituloModal = "Enviar Reconsideración"
                  // Lógica para enviar la reconsideración
                  modalComentario(saveNewDataFrom, txtTituloModal, formik.values);
                }
              } 

            }}
            >

            {/* Texto botón para: */}
            {/* Analista que le solicitan reconsideración*/}
            {(localStorage.getItem('id_perfil') === '4' && guardadoTemporal === false) && "Enviar Reconsideración"}
            
            {/* Analista que debe finalizar guardado temporal */}
            {(localStorage.getItem('id_perfil') === '4' && guardadoTemporal === true) && "Finalizar"}
            
            {/* Perfil Recursos Humanos */}
            {(localStorage.getItem('id_perfil') === '3') && "Guardar"}

          </button>
        </div>
      </div>
    </>
  )
}
