import { useState } from "react";
import { returnAnio } from "../../utils/funciones";
import { errDigitosAnio } from "../../utils/Constants";
import moment from "moment";

export const useDatosPersonalesExtranjero = () => {
  const [datosExtranjeroErr, setErrorss] = useState({});
  let errrObj = {};
  const validateVisaFechas = (desde, hasta) => {
    if (returnAnio(desde) < 1000) { errrObj.visaTemporariaDesde = errDigitosAnio; }
    if (returnAnio(hasta) < 1000) { errrObj.visaTemporariaHasta = errDigitosAnio; }

    const diferenciaDias = moment(new Date(hasta)).diff(moment(new Date(desde)), 'days');

    if (diferenciaDias < 1) { errrObj.visaTemporariaHasta = "La fecha de vencimiento debe ser mayor"; }
  }

  const validatePersonalesExtranjero = (obj) => {
    const { tipoVisa, visaTemporariaDesde, visaTemporariaHasta, nacionalidad } = obj;

    if (parseInt(nacionalidad) !== 1 && !visaTemporariaDesde) { errrObj.visaTemporariaDesde = "Requerido"; }

    validateVisaFechas(visaTemporariaDesde, visaTemporariaHasta);

    if (parseInt(nacionalidad) !== 1 && !tipoVisa) { errrObj.tipoVisa = "Requerido"; }
    if (parseInt(nacionalidad) !== 1 && !visaTemporariaHasta) { errrObj.visaTemporariaHasta = "Requerido"; }
    
    setErrorss(errrObj);
  }

  return { datosExtranjeroErr, validatePersonalesExtranjero }
}