import { useState, useEffect } from "react";
import { paginationComponentOptions, objStyleCol } from "../utils/Constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { BtnDescargarExcel } from "./BtnDescargarExcel";
import DataTable from 'react-data-table-component';
import { customStyles } from "../utils/Constants";
import { momentFecha } from "../utils/funciones";
import { InputBusquedaMonitor } from "./InputBusqueda";
import { usePagina } from "../hooks/hookPagina";
import { useNavigate } from "react-router-dom";
import Spinner from "./ui/Spinner";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icono } from "./ui/Iconos";

export const ResumenSolicitudes = () => {
  const { loading, lista, llamarPaginaResumen, totalRows, listaExcel, llamarSolicitudesExcel, setLista } = usePagina();
  const [cantFilasPagina, setCantFilasPagina] = useState(15);
  const [pageTable, setPageTable] = useState(1);
  const [filterArr, setFilterArr] = useState([]);
  const [listaBusqueda, setListaBusqueda] = useState([]);
  const navigate = useNavigate();

  const devolverFecha = fecha => fecha ? momentFecha(fecha) : '';



  const columnas = [
    {
      name: 'Rut colaborador',
      sortable: false,
      selector: row => row.rut,
      style: objStyleCol,
      width: '110px',
    },
    {
      name: 'Nombre colaborador',
      sortable: false,
      selector: row => row.nombre,
      wrap: true,
      minWidth: '150px',
    },
    {
      name: 'Instalación / Unidad Solicitante',
      sortable: false,
      selector: row => renderInfoInstalacion(row),
      wrap: true,
      minWidth: '120px',
    },
    {
      name: 'Fecha registro',
      sortable: false,
      selector: row => devolverFecha(row.fecha_registro),
      style: objStyleCol,
      width: '100px'
    },
    {
      name: 'Fecha ingreso',
      sortable: false,
      selector: row => row.fecha_ingreso === 'No aplica' ? '----' : devolverFecha(row.fecha_ingreso),
      style: objStyleCol,
      width: '80px'
    },
    {
      name: 'Solicitud Ingreso',
      sortable: false,
      selector: row => devolverFecha(row.solicitud_ingreso),
      style: objStyleCol,
      width: '85px'
    },
    {
      name: 'Corrección ingreso',
      sortable: false,
      selector: row => devolverFecha(row.correccion_ingreso),
      style: objStyleCol,
      width: '90px'
    },
    {
      name: 'Aprobación ingreso',
      sortable: false,
      selector: row => devolverFecha(row.aprobacion_ingreso),
      style: objStyleCol,
      width: '90px'
    },
    {
      name: 'Validación ingreso',
      wrap: true,
      sortable: false,
      selector: row => devolverFecha(row.validacion_ingreso),
      style: objStyleCol,
      headerStyle: { overflowX: 'scroll' },
      width: '90px'
    },
    {
      name: 'Rechazada',
      wrap: true,
      sortable: false,
      selector: row => row.responsable,
      style: objStyleCol,
      width: '220px',
    },
    {
      name: 'Días en transito',
      sortable: false,
      selector: row => row.dias_transito.split(' days')[0],
      style: objStyleCol,
      width: '80px',
    },
    {
      name: 'Ver',
      sortable: false,
      selector: row => row.dias_transito,
      cell: row => {
        return (
          <button
            className="btn btn-primary btnAccion btn-sm borderBlanco"
            data-bs-toggle="tooltip" title="Ver solicitud"
            onClick={() => { verSolicitudDetalle(row) }}
          >
            <FontAwesomeIcon icon={faEye} title='Ver solicitud' />
          </button>
        )
      },
      style: objStyleCol,
      width: '45px',
    },
  ]

  const renderInfoInstalacion = (row) => {
    const cellStyle = { cursor: 'pointer', display: 'inline-block', width: '100%' };

    if (row.solicitud_estado === 18) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-${row.id}`}>
              <span>
                <p>La solicitud se encuentra pausada por falta de información del área de Procesos & Remuneraciones.</p>
              </span>
            </Tooltip>
          }
        >
          <div style={cellStyle}>{row.instalacion} <Icono iconName='infoInstalacion' /></div>
        </OverlayTrigger>
      );
    } else if (row.solicitud_estado === 16) {
      return (
        <div className="text-center">Solicitud declinada</div>
      );
    } else {
      return (
        <div>{row.instalacion}</div>
      );
    }
  };

  const headerArr = [
    'RUT COLABORADOR',
    'NOMBRE COLABORADOR',
    'INSTALACION SOLICITANTE',
    'FECHA REGISTRO',
    'FECHA INGRESO',
    'SOLICITUD INGRESO',
    'RESPONSABLE SOLICITUD',
    'CORRECCION INGRESO',
    'APROBACION INGRESO',
    'RESPONSABLE APROBACION',
    'VALIDACION INGRESO',
    'RESPONSABLE VALIDACION',
    'RECHAZADA',
    'DIAS EN TRANSITO'
  ]

  const columMuestreo = [
    "rut",
    "nombre",
    "instalacion",
    "fecha_registro",
    "fecha_ingreso",
    "solicitud_ingreso",
    "solicitud_ingreso_responsable",
    "correccion_ingreso",
    "aprobacion_ingreso",
    "aprobacion_ingreso_responsable",
    "validacion_ingreso",
    "validacion_ingreso_responsable",
    "responsable",
    "dias_transito"
  ]

  const acondicionarLista = (lista) => {
    if (lista.length > 0) {
      const listaAcond = lista.map(el => {
        el = {
          ...el,
          solicitud_ingreso: el.solicitud_ingreso,
          aprobacion_ingreso: el.aprobacion_ingreso,
          correccion_ingreso: el.correccion_ingreso,
          validacion_ingreso: el.validacion_ingreso,
          responsable: el.responsable,
          solicitud_estado: el.solicitud_estado,
        }
        const { arraySeguimiento, ...ell } = el
        return ell
      });


      setListaBusqueda(listaAcond);
      setFilterArr(Object.keys(listaAcond[0]).filter((el) => {
        return (
          el !== "id" &&
          el !== "id_persona" &&
          el !== "solicitud_estado" &&
          el !== "estado_log" &&
          el !== "estado_created_log"
        )
      }));
    }
    else {
      setListaBusqueda([]);
    }
  }

  useEffect(() => {
    if (totalRows == 0) return
    llamarSolicitudesExcel('resumensolicitudesv2', {});
  }, [totalRows]);

  useEffect(() => {
    acondicionarLista(lista);
  }, [lista]);

  useEffect(() => {
    llamarPaginaResumen('resumensolicitudes', { page: pageTable, total_page: cantFilasPagina });
  }, []);

  const verSolicitudDetalle = async (props) => {
    const { id, id_persona, solicitud_estado } = props;
    return navigate(`/vacante/${id}`, { state: { idSolicitud: id, idPersonaSolicitud: id_persona, solicitud: { id_soliditud_estado: solicitud_estado }, editable: false } });
  }

  const filtrar = (txt) => {
    const regExpr = new RegExp(`${txt}`, 'i');

    const listaFiltrada = lista.filter(el => {
      return (
        regExpr.test(el.rut) ||
        regExpr.test(el.nombre) ||
        regExpr.test(el.instalacion) ||
        regExpr.test(el.rechazada) ||
        regExpr.test(el.fecha_ingreso)
      )
    });

    acondicionarLista(listaFiltrada);
  }

  const acondicionarDatos = async () => {
    return {

      nombreArchivo: `resumenSolicitudes_${momentFecha(new Date())}`,
      columNombresTabla: headerArr,
      datosTabla: listaExcel,
      columMuestreo: columMuestreo,
      anchoColumnas: [7, 19, 12, 7, 7, 7, 15, 7, 7, 15, 7, 15, 9, 7]
    }
  }

  const handlePageChange = page => {

    setPageTable(page);
  };

  useEffect(() => {
    llamarPaginaResumen('resumensolicitudes', { page: pageTable, total_page: cantFilasPagina });

  }, [pageTable, cantFilasPagina]);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 ">
          <div className="card">
            <div className="card-header bgArcopime h5 d-flex justify-content-between" style={{ padding: '6px 8px 10px 8px' }}>
              Resumen solicitudes
              <InputBusquedaMonitor
                ejecutar={filtrar}
                searchDisabled={listaExcel.length === 0 ? true : false}
              />
            </div>
            {
              loading
                ? (<Spinner />)
                : (
                  <div className="card-body contenedorTabla">
                    {lista.length > 0
                      ?
                      (
                        <DataTable
                          title={false}
                          responsive={true}
                          columns={columnas}
                          data={listaBusqueda}
                          progressPending={loading}
                          pagination
                          paginationServer
                          paginationDefaultPage={pageTable}
                          paginationPerPage={cantFilasPagina}
                          customStyles={customStyles}
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={filas => { setCantFilasPagina(filas) }}
                          onChangePage={handlePageChange}
                          paginationComponentOptions={paginationComponentOptions}
                          dense
                          fixedHeader
                          fixedHeaderScrollHeight="450px"
                          noDataComponent="No se encontraron registros."
                        />
                      )
                      :
                      <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                    }
                  </div>
                )
            }
          </div>
        </div>
      </div>
      {(listaBusqueda.length > 0) &&
        <div className="row mt-3">
          <div className="col-sm-12">
            {listaExcel.length === 0 ?
              <BtnDescargarExcel ejecutarFn={null} nombreBoton={'Preparando data'} botonDisabled={true} />
              : <BtnDescargarExcel ejecutarFn={acondicionarDatos} nombreBoton={'Descarga Excel'} botonDisabled={false} />
            }
          </div>
        </div>
      }
    </>
  )
}