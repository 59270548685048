import { mensajeSimple } from "../components/ui/sweetAlerts";
import { BASE_URL_PRODUCCION, objNoSuccess } from "../utils/Constants";
import { cerrarSession } from "../utils/funciones";
const OBTENER_DATOS_RUT_SOLICITANTE_EXITO = "OBTENER_DATOS_RUT_SOLICITANTE_EXITO";
const LOADING = "LOADING"

const dataInicial = {
  dataPersona: {
    rut: "",
    data: {},
    status: ""
  },
  loading: false
}

//reducer
export default function datosPersonaSolicitanteReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true }
    case OBTENER_DATOS_RUT_SOLICITANTE_EXITO:
      return { ...state, dataPersona: action.payload, loading: false }
    default:
      return state;
  }
}

//accion de consumir api
const BASE_URL = BASE_URL_PRODUCCION;

export const obtenerDatosPersonaSolicitanteAction = (rutCompleto, idSolicitud = false) => async (dispatch, getState) => {
  dispatch({ type: LOADING });

  const params = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  }

  try {
    const url = `${BASE_URL}/verificarpostulante?RutPersona=${rutCompleto}`;
    const response = await fetch(url, params);
    const responseJSON = await response.json();

    if (!responseJSON.data) { return false; }

    dispatch({
      type: OBTENER_DATOS_RUT_SOLICITANTE_EXITO,
      payload: {
        rut: rutCompleto,
        data: responseJSON.data,
        idSolicitud: idSolicitud,
        status: responseJSON.status,
        message: responseJSON.message
      }
    })

    return true
  } catch (error) {
    console.log("error :", error)
    console.log({error})
    return false
  }
}