import ErrorInput from "../ui/ErrorInput";

export const InputFecha = ({ label, subObjeto, minima, maxima, formik }) => {

  return (
    <>
      <label htmlFor="">{label}</label>
      <input
        type="date"
        name={`${subObjeto[0]}.${subObjeto[1]}`}
        min={minima}
        max={maxima}
        className="form-control"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[subObjeto[0]][subObjeto[1]]}
      />
      {formik.errors.datosLaborales !== undefined &&
        formik.touched.datosLaborales !== undefined && (
          <ErrorInput
            touched={formik.touched[subObjeto[0]][subObjeto[1]]}
            error={formik.errors[subObjeto[0]][subObjeto[1]]}
          />
        )}
    </>
  )
}