import { BASE_URL_PRODUCCION } from "../utils/Constants";

// constantes 
const OBTENER_DATOS_VACANTES_EXITO = 'OBTENER_DATOS_VACANTES_EXITO'

const OBTENER_DETALLE_VACANTES_EXITO = 'OBTENER_DETALLE_VACANTES_EXITO'

const GUARDAR_DATOS_PERSONALES_VACANTE_EXITO = 'GUARDAR_DATOS_PERSONALES_VACANTE_EXITO'

const LOADING = 'LOADING'

const ERROR_GUARDAR_DATA = 'ERROR_GUARDAR_DATA'

const dataInicial = {
    array: [],
    detalleVacante: [],
    idPersona: null,
    vacantePersona: [],
    loading: false,

}

// reducer
export default function datosVacantesReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING:
            return { ...state, loading: true }
        case ERROR_GUARDAR_DATA:
            return { ...dataInicial }
        case OBTENER_DATOS_VACANTES_EXITO:
            return { ...state, array: action.payload, loading: false }
        case OBTENER_DETALLE_VACANTES_EXITO:
            return { ...state, detalleVacante: action.payload, loading: false }
        case GUARDAR_DATOS_PERSONALES_VACANTE_EXITO:
            return { ...state, idPersona: action.payload, loading: false }
        default:
            return state;
    }
}

// acciones
// URL para el consumo de api
const BASE_URL = BASE_URL_PRODUCCION;
const params = () => {
    return  {
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }
}

export const obtenerDatosVacantesAction = (page,totalPage) => async (dispatch, getState) => {
    dispatch({
        type: LOADING
    })
    try {
        const url = `${BASE_URL}/solicitudproceso?page=${page}&total_page=${totalPage}`;

        const response = await fetch(url,params())
        const responseJSON = await response.json();
        dispatch({
            type: OBTENER_DATOS_VACANTES_EXITO,
            payload: responseJSON
        })

    } catch (error) {
        console.log("error :", error)
    }
}

export const obtenerDetalleVacanteAction = (idSolicitud) => async (dispatch, getState) => {
    dispatch({
        type: LOADING
    })
    try {
        const url = `${BASE_URL}/solicitudprocesolog?idSolicitud=${idSolicitud}`;

        const response = await fetch(url,params())
        const responseJSON = await response.json();
        dispatch({
            type: OBTENER_DETALLE_VACANTES_EXITO,
            payload: responseJSON.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}

export const guardarDatosVacante = (datos, rutConsultado) => async (dispatch, getState) => {
    try {
        const url = `${BASE_URL}/persona`;
        const { rut, id_persona, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento, sexo, estadoCivil, nivelFormacion, nacionalidad, email, telefonoCelular } = datos.datosPersonales;
        const params = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({
                nombre: nombres,
                apellido_pat: apellidoPaterno,
                apellido_mat: apellidoMaterno,
                identificacion: rut,
                fecha_nacimiento: fechaNacimiento,
                correo: email,
                telefono: telefonoCelular,
                id_persona_genero: sexo,
                id_persona_estado_civil: estadoCivil,
                id_educacion_nivel: nivelFormacion,
                id_pais: nacionalidad
            })
        };

        const response = await fetch(url, params)

        if (response.ok) {
            //1
            const responseJSON = await response.json()

            //1.5
            const solicitud = guardarSolicitud(responseJSON.data)

            //2
            guardarDatosPersonaExtranjera(responseJSON.data.id_persona, datos.datosPersonaExtranjera)

            if (solicitud) {
                //3
                guardarDatosLaborales(responseJSON.data.id_persona, solicitud.data.id_solicitud, datos.datosLaborales)

            }

            //4
            guardarDatosBancarios(responseJSON.data.id_persona, datos.datosBancarios)

            //5
            guardarDatosPrevision(responseJSON.data.id_persona, datos.datosPrevision)

            //6
            guardarDatosDireccion(responseJSON.data.id_persona, datos.datosDireccion)

            dispatch({
                type: GUARDAR_DATOS_PERSONALES_VACANTE_EXITO,
                payload: responseJSON.data.id_persona
            })
        }

    } catch (error) {
        console.log("error :", error)
    }
}

const guardarSolicitud = async (data) => {
    try {
        const url = `${BASE_URL}/solicitud`;
        const params = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${auth.token}`,
            },
            method: "POST",
            body: JSON.stringify({
                idUsuario: data.id_persona,
            })
        };

        const response = await fetch(url, params)
        const responseJSON = await response.json()
        return responseJSON;

    } catch (error) {
        console.log("error :", error)
    }
}

const guardarDatosLaborales = async (idPersonaSolicitante, idSolicitud, datosLaborales) => {
    try {
        const url = `${BASE_URL}/vacante`;
        const { fechaIngreso, cargo, planHorario, rolTemporis, tipoContrato, fechaFinContrato } = datosLaborales;
        const params = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${auth.token}`,
            },
            method: "POST",
            body: JSON.stringify({
                tipo_contrato: tipoContrato,
                fecha_ingreso: fechaIngreso,
                fecha_retiro: fechaFinContrato,
                temporis_plan_horario: {
                    data: {
                        planHorario
                    }
                },
                temporis_unidad_rol: {
                    data: {
                        rolTemporis
                    }
                },
                id_persona: idPersonaSolicitante,
                id_solicitud: idSolicitud
            })
        };
        const response = await fetch(url, params)
        const responseJSON = await response.json()
        return responseJSON
    } catch (error) {
        console.log("error :", error)
    }
}

const guardarDatosPersonaExtranjera = async (idPersonaSolicitante, datosPersonaExtranjera) => {
    try {
        const url = `${BASE_URL}/persona`;
        const { tipoVisa, visaTemporariaDesde, visaTemporariaHasta } = datosPersonaExtranjera;
        const params = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${auth.token}`,
            },
            method: "POST",
            body: JSON.stringify({
                fecha_visa_temporaria: visaTemporariaDesde,
                fecha_vencimiento_visa: visaTemporariaHasta,
                id_persona_tipo_visa: tipoVisa,
                id_persona: idPersonaSolicitante
            })
        };
        const response = await fetch(url, params)
        const responseJSON = await response.json()
        return responseJSON
    } catch (error) {
        console.log("error :", error)
    }
}

const guardarDatosDireccion = async (idPersonaSolicitante, datosDireccion) => {
    try {
        const url = `${BASE_URL}/personadireccion`;
        const { calle, numeroCasa, numeroDepartamento, region, comuna, } = datosDireccion;
        const params = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${auth.token}`,
            },
            method: "POST",
            body: JSON.stringify({
                direccion: calle,
                id_persona: "",
                id_comuna: comuna,
                numero: numeroCasa,
                numero_departamento: numeroDepartamento,
                id_region: region,
                id_direccion_tipo: 1,
                id_persona: idPersonaSolicitante
            })
        };
        const response = await fetch(url, params)
        const responseJSON = await response.json()
        return responseJSON
    } catch (error) {
        console.log("error :", error)
    }
}

const guardarDatosPrevision = async (idPersonaSolicitante, datosPrevision) => {
    try {
        const url = `${BASE_URL}/personaprevision`;
        const { jubilado, previsionSalud, previsionSocial, planPactado } = datosPrevision;
        const params = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${auth.token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                id_afp: previsionSocial,
                id_salud: previsionSalud,
                id_salud_plan: planPactado,
                id_persona: idPersonaSolicitante,
                jubilado
            })
        };
        const response = await fetch(url, params)
        const responseJSON = await response.json()
        return responseJSON
    } catch (error) {
        console.log("error :", error)
    }
}

const guardarDatosBancarios = async (idPersonaSolicitante, datosBancarios) => {
    try {
        const url = `${BASE_URL}/personadatosbancarios`;
        const { formaDePago, banco, tipoCuenta, numeroCuenta } = datosBancarios;
        const params = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${auth.token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                numero_cuenta: numeroCuenta,
                id_banco: banco,
                id_tipo_cuenta: tipoCuenta,
                id_forma_pago: formaDePago,
                id_persona: idPersonaSolicitante
            })
        };
        const response = await fetch(url, params)
        const responseJSON = await response.json()
        return responseJSON
    } catch (error) {
        console.log("error :", error)
    }
}

const guardarDatosDocumentos = async (idPersonaSolicitante, datosDocumentos) => {
    try {
        const url = `${BASE_URL}/`;
        const { curriculum, cedula, antecedentes, residencia, afp, salud, visa, discapacidad, covid, jubilacion, } = datosDocumentos;
        const params = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${auth.token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                id_persona: idPersonaSolicitante
            })
        };
        const response = await fetch(url, params)
        const responeJSON = await response.json()

    } catch (error) {
        console.log("error :", error)
    }
}

