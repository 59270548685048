import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { evaluarEstadoBadge, capitalizeFirstLetter } from '../../utils/funciones';
import { faEye } from '@fortawesome/free-solid-svg-icons';



export const getColumnas = (objStyleCol, verDetalleLog) => ({
    temporis: [
        {
            name: 'ID',
            sortable: true,
            selector: row => row.id,
            width: '95px',
            style: objStyleCol
        },
        {
            name: 'Administrador solicitud',
            sortable: true,
            selector: row => capitalizeFirstLetter(row.user_creator_name),
            // width: '250px',
            style: objStyleCol
        },
        {
            name: 'Estado',
            sortable: true,
            selector: row => evaluarEstadoBadge(row.status),
            wrap: true,
            style: objStyleCol,
            width: '95px',
        },
        {
            name: 'Mensaje',
            sortable: true,
            selector: row => row.resp_clean_temporis,
            // width: '300px',
            style: objStyleCol
        },
        {
            name: 'Creado',
            sortable: true,
            selector: row => row.created_at,
            width: '150px',
            style: objStyleCol
        },
        {
            name: 'Acciones',
            sortable: false,
            wrap: true,
            width: '95px',
            button: true,
            style: objStyleCol,
            cell: (row) => {
                return (
                    <div className='d-flex w-100 justify-content-center'>
                        <button
                            className="btn btnAccion btn-primary btn-sm"
                            data-bs-toggle="tooltip" title="Ver detalle"
                            onClick={() => { verDetalleLog(row); }}
                        >
                            <FontAwesomeIcon icon={faEye} title='Ver detalle' />
                        </button>
                    </div>
                )
            }
        }
    ]
})


