
import { obtenerPosicionesLaboralesApi } from "../api/contratacion/datosLaboralesApi";
import { obtenerVacanteApi } from "../api/contratacion/solicitudVacanteApi";

// constantes
const OBTENER_DATOS_LABORALES_EXITO = 'OBTENER_DATOS_LABORALES_EXITO';
const OBTENER_POSICIONES_LABORALES_EXITO = 'OBTENER_POSICIONES_LABORALES_EXITO';
const OBTENER_DATOS_TEMPORIS_EXITO = 'OBTENER_DATOS_TEMPORIS_EXITO'

const dataInicial = {
  array: [],
  posicionesLaborales: [],
  temporis: []
}

// reducer aceptan los datos para cambiar los estados
export default function datosLaboralesReducer(state = dataInicial, action) {
  switch (action.type) {
    case OBTENER_DATOS_LABORALES_EXITO:
      return { ...state, array: action.payload }
    case OBTENER_POSICIONES_LABORALES_EXITO:
      return { ...state, posicionesLaborales: action.payload }
    case OBTENER_DATOS_TEMPORIS_EXITO:
      return { ...state, temporis: action.payload }
    default:
      return state
  }
}

// acciones son las que van a consumir las apis | con dispatch activo el reducer y con el getState obtengo la info de dataInicial.
export const obtenerDatosLaboralesAction = (idPersona, idSolicitud) => async (dispatch, getState) => {
  try {
    const res = await obtenerVacanteApi(idPersona, idSolicitud);

    dispatch({
      type: OBTENER_DATOS_LABORALES_EXITO,
      payload: res.data
    })
  } catch (error) {
    console.log("error :", error)
  }
}

export const obtenerPosicionesLaboralesAction = () => async (dispatch, getState) => {
  try {
    const posiciones = await obtenerPosicionesLaboralesApi()

    dispatch({
      type: OBTENER_POSICIONES_LABORALES_EXITO,
      payload: posiciones.data
    })

  } catch (error) {
    console.log("Error :", error)
  }
}