import { BASE_URL_PRODUCCION } from "../utils/Constants";
//constantes
const OBTENER_DATOS_PAISES_EXITO = 'OBTENER_DATOS_PAISES_EXITO'

const OBTENER_DATOS_REGIONES_EXITO = 'OBTENER_DATOS_REGIONES_EXITO'

const OBTENER_DATOS_COMUNAS_EXITO = 'OBTENER_DATOS_COMUNAS_EXITO'

const OBTENER_TIPO_DIRECCION_EXITO = 'OBTENER_TIPO_DIRECCION_EXITO'

const dataInicial = {
    paises: [],
    regiones: [],
    comunas: [],
    tipoDireccion: []
}

//reducer 
export default function datosZonasReducer(state = dataInicial, action) {
    switch (action.type) {
        case OBTENER_DATOS_PAISES_EXITO:
            return { ...state, paises: action.payload }
        case OBTENER_DATOS_REGIONES_EXITO:
            return { ...state, regiones: action.payload }
        case OBTENER_DATOS_COMUNAS_EXITO:
            return { ...state, comunas: action.payload }
        case OBTENER_TIPO_DIRECCION_EXITO:
            return { ...state, tipoDireccion: action.payload }
        default:
            return state;
    }
}

//acciones 

// URL para el consumo de api
const BASE_URL = BASE_URL_PRODUCCION;
const params = () => {
    return {
        headers:{
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }
}

export const obtenerPaisesAction = () => async (dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/pais`;
        const response = await fetch(url,params())
        const result = await response.json();
        dispatch({
            type: OBTENER_DATOS_PAISES_EXITO,
            payload: result.data
        })

    } catch (error) {
        console.log('error :', error);
    }
}

export const obtenerRegionesAction = (idPais = 1) => async (dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/regiones?idPais=${idPais}`;
        const response = await fetch(url,params())
        const result = await response.json();
        dispatch({
            type: OBTENER_DATOS_REGIONES_EXITO,
            payload: result.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}



export const obtenerComunasAction = (idRegion) => async (dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/comuna?idComuna=${idRegion}`;
        const response = await fetch(url,params())
        const result = await response.json();
        dispatch({
            type: OBTENER_DATOS_COMUNAS_EXITO,
            payload: result.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}

export const obtenerTipoDireccion = () => async (dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/direcciontipo`;
        const res = await fetch(url,params())
        const resJSON = await res.json()
        dispatch({
            type: OBTENER_TIPO_DIRECCION_EXITO,
            payload: resJSON.data
        })
    } catch (error) {
        console.log("Error :", error)
    }
}
